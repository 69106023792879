<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card style="margin-left: 5px;margin-right: 5px">
            <div style="display: flex; justify-content: space-between;"> 
              <div>
                <b-card-title>
                   <h3 class="app-title mdsm">{{ this.filterDataMetricWiseType.label }} Trend</h3>
              </b-card-title>
              </div>
              <div style="display: flex; justify-content: space-between; gap: 10px;">
                <div class="selectBox" style="margin-top: -15px;">
                  <label>Channel:</label>
                  <br />
                  <b-form-select style="text-transform: capitalize; font-size: 12px; width: 110px !important; " :options="sourceType" label="title"
                    v-model="selectedSource" @input="handleTrendsMetricsChange">
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
              <div>
                <div class="selectBox" style="margin-top: -15px;">
                <label>Metric:</label>
                  <br />
                <b-form-select 
                style="text-transform: capitalize; font-size: 12px ; width: 130px !important;"
                v-model="filterDataMetricWiseType"
              >
                <option
                  v-for="(option , index) in getAllMetricsName"
                  :key="index"
                  :value="option"
                >
                  <span> 
                    {{ option.label }}
                  </span>
                </option>
              </b-form-select>
               </div>  
              </div>
              </div>
            </div>
          <div
            v-if="ifFetchedTrends || ifClientFetchedTrends"
            class="spinnerCentered"
          >
            <multiChannelSpinner />
          </div>
          <div v-else class="chartContainerClass">
            <apexchart class="trendscharts"
              type="line"
              height="310"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>

          <!-- 
                <apexchart :options="chartOptions" :series="series" ref="chart"></apexchart> -->
          <!-- <apexchart type="bar" height="430" :options="chartOptions" :series="series"></apexchart> -->
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card style="margin-right: 5px; margin-left: 5px;">
          <div style="display: flex; justify-content: space-between">
              <div>
                <b-card-title>
                <h3 class="app-title mdsm">Last 30 Days vs Yesterday({{ this.filterData30DaysMetricWiseType.label }})</h3>
              </b-card-title>
              </div>
              <div style="display: flex; justify-content: space-between; gap: 10px;">
                <div>
                <div class="selectBox" style="margin-top: -10px;">
                <b-form-select 
                style="text-transform: capitalize; font-size: 13px ; width: 125px !important; "
                v-model="filterData30DaysMetricWiseType"
              >
                <option
                  v-for="(option , index) in get30DaysAllMetricsName"
                  :key="index"
                  :value="option"
                >
                  <span> 
                    {{ option.label }}
                  </span>
                </option>
              </b-form-select>
               </div>  
              </div>
                <input
                type="text"
                style="width: 300px; margin-top: -10px;"
                v-model="searchTerm"
                class="searchInput"
                placeholder="Search by Project name"
              />
              </div>
          </div>
          <div style="overflow-x: auto; overflow-y: auto;">
            <div
            v-if="ifFetchedTableDataTrends || ifClientFetchedTrends"
            class="spinnerCentered"
          >
            <multiChannelSpinner />
          </div>
           <div v-else>
              <vue-good-table
                class="custom-data-table-trends"
                :rows="paginatedCPLTrendRows"
                :columns="tableCPLTrendColumns"
                :pagination-options="{
                  enabled: true
                }"
                 :search-options="{
                  enabled: true,
                  externalQuery: searchTerm
                }"
              >
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1"></div>
                    <div>
                      <b-pagination
                        v-model="currentCPLTrendPage"
                        :total-rows="filteredTableData.length"
                        :per-page="pageCPLTrendLength"
                        first-number
                        last-number
                        align="center"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="updateCPLTrendPage"
                      >
                        <template #prev-text>
                          <span>«</span>
                        </template>
                        <template #next-text>
                          <span>»</span>
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
           </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12">
        <b-card style="margin-left: 5px;margin-right: 5px;">
          <div style="display: flex; justify-content: space-between">
            <div>
              <b-card-title>
                <h3 class="app-title mdsm">Quality Vs Volume ({{ this.filterDataQualityVolumeWiseType.label }})<span style="font-size: 12px !important; margin-left: 5px;">{{ selectedDates }}</span> </h3>
              </b-card-title>
            </div>
            <div>
              <b-form-select 
                style="text-transform: capitalize; font-size: 13px ; width: 125px !important;"
                v-model="filterDataQualityVolumeWiseType"
              >
                <option
                  v-for="(option , index) in getAllQualityVolumeName"
                  :key="index"
                  :value="option"
                >
                  <span> 
                    {{ option.label }}
                  </span>
                </option>
              </b-form-select>
            </div>
          </div>
          <div v-if="isQualityVolumeChartDataFetched"   class="spinnerCentered">
            <multiChannelSpinner />
          </div>
          <div v-else>
            <apexchart
            type="bar"
            height="310"
            :options="chartBarOptions"
            :series="seriesBar"
          ></apexchart>
          </div>
        </b-card>
      </b-col>
    </b-row> -->
    <!-- <b-row>
      <b-col cols="12">
        <b-card style="margin-left: 5px;margin-right: 5px;">
          <div style="display: flex; justify-content: space-between">
            <div>
              <b-card-title>
                <h3 class="app-title mdsm">{{ this.filterLeadsWiseType.label }}<span style="font-size: 12px !important; margin-left: 5px;">{{ selectedDates }}</span></h3>
              </b-card-title>
            </div>
            <div style="display: flex; justify-content: space-between; gap: 10px; margin-bottom: 10px;">
              <b-form-select 
                style="text-transform: capitalize; font-size: 13px ; width: 125px !important;"
                v-model="filterLeadsWiseType"
              >
                <option
                  v-for="(option , index) in getLeadsName"
                  :key="index"
                  :value="option"
                >
                  <span> 
                    {{ option.label }}
                  </span>
                </option>
              </b-form-select>
              <input
                type="text"
                style="width: 300px;"
                v-model="searchLeadsTerm"
                class="searchInput"
                placeholder="Search by Project name"
              />
            </div>
          </div>
          <div
            v-if="isLeadsDataFetched || ifClientFetchedTrends"
            class="spinnerCentered"
          >
            <multiChannelSpinner />
          </div>
          <div v-else>
            <vue-good-table
            class="custom-data-table-trends"
            :rows="paginatedLeadsCPLTrendRows"
            :columns="tableLeadsCPLDiffColumns"
            :pagination-options="{
              enabled: true
            }"
             :search-options="{
                  enabled: true,
                  externalQuery: searchLeadsTerm
           }"
          >
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1"></div>
                <div>
                  <b-pagination
                    v-model="currentLeadsCPLTrendPage"
                    :total-rows="filteredLeadsTableData.length"
                    :per-page="pageLeadsCPLTrendLength"
                    first-number
                    last-number
                    align="center"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="updateLeadsCPLTrendPage"
                  >
                    <template #prev-text>
                      <span>«</span>
                    </template>
                    <template #next-text>
                      <span>»</span>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          </div>
         
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BPagination,
  BFormSelect
} from "bootstrap-vue";
import projectsMap from "@/@core/utils/projectDetailMap";
import { VueGoodTable } from "vue-good-table";
import VueApexCharts from "vue-apexcharts";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
export default {
  data() {
    return {
      searchTerm:"",
      searchLeadsTerm:"",
      tablesTrendsWiseData:[],
      tablesLeadsTrendsWiseData:[],
      sourceType: ["all", "googleAds", "meta"],
      selectedSource: "all",
      filterDataZoneWiseType: "All Zones",
      currentCPLTrendPage: 1,
      currentCPLDiffPage: 1,
      currentLeadsCPLTrendPage: 1,
      pageCPLDIffLength: 8,
      pageCPLTrendLength: 8,
      pageLeadsCPLTrendLength: 8,
      filterDataMetricWiseType: { label: "CPL", value: "cpc" }, 
       getAllMetricsName: [
        { label: "Clicks", value: "clicks" },
        { label: "Impressions", value: "impressions" },
        { label: "Spends", value: "spend" },
        { label: "CPM", value: "cpm" },
        // { label: "CPC", value: "cpcl" },
        { label: "CTR", value: "ctr" },
        { label: "Leads", value: "conversions" },
        { label: "CPL", value: "cpc" },
      ],
      filterDataQualityVolumeWiseType: { label: "CPL", value: "cpc" }, 
      filterLeadsWiseType: { label: "CPL", value: "cpc" }, 
       getLeadsName: [
        { label: "Clicks", value: "clicks" },
        { label: "Impressions", value: "impressions" },
        { label: "Spends", value: "spend" },
        { label: "CPM", value: "cpm" },
        // { label: "CPC", value: "cpcl" },
        { label: "CTR", value: "ctr" },
        { label: "Leads", value: "conversions" },
        { label: "CPL", value: "cpc" },
      ],
      getAllQualityVolumeName: [
        { label: "Clicks", value: "clicks" },
        { label: "Impressions", value: "impressions" },
        { label: "Spends", value: "spend" },
        { label: "CPM", value: "cpm" },
        // { label: "CPC", value: "cpcl" },
        { label: "CTR", value: "ctr" },
        { label: "Leads", value: "conversions" },
        { label: "CPL", value: "cpc" },
      ],
      filterData30DaysMetricWiseType: { label: "CPL", value: "cpc" }, 
      get30DaysAllMetricsName: [
        { label: "Clicks", value: "clicks" },
        { label: "Impressions", value: "impressions" },
        { label: "Spends", value: "spend" },
        { label: "CPM", value: "cpm" },
        // { label: "CPC", value: "cpcl" },
        { label: "CTR", value: "ctr" },
        { label: "Leads", value: "conversions" },
        { label: "CPL", value: "cpc" },
      ],
      tableCPLDiffColumns: [
        {
          label: "Project Name",
          field: "name",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: "Spends",
          field: "age",
          type: "number",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: "Leads",
          field: "age",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: "CPL",
          field: "age",
          tdClass: "text-center",
          thClass: "text-center"
        }
      ],
      tableLeadsCPLDiffColumns :[
  {
    label: "Project Name",
    field: "projectName",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Total",
    field: "total",
    type: "number",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Meta Lead Gen",
    field: "LeadGen",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Meta Quality Lead Gen",
    field: "LeadGen_Quality",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Meta Volume Lead Gen",
    field: "LeadGen_Volume",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Meta Call Campaign",
    field: "Call",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Meta Reach Campaign",
    field: "Reach",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Google Search",
    field: "Search",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Google Discovery",
    field: "Discovery",
    tdClass: "text-center",
    thClass: "text-center"
  },
  // Uncomment and adjust these as necessary
  {
    label: "Google PMax",
    field: "PMax",
    tdClass: "text-center",
    thClass: "text-center"
  },
  {
    label: "Google Video",
    field: "Video",
    tdClass: "text-center",
    thClass: "text-center"
  }
],
      tableCPLTrendData: [
        { id: 1, name: "John Doe", age: 25, country: "USA" },
        { id: 2, name: "Jane Smith", age: 30, country: "Canada" },
        { id: 3, name: "Sam Green", age: 35, country: "UK" },
        { id: 4, name: "Alice Brown", age: 28, country: "Australia" },
        { id: 5, name: "Michael Johnson", age: 40, country: "New Zealand" },
        { id: 6, name: "Chris Lee", age: 22, country: "Singapore" },
        { id: 7, name: "Patricia Kim", age: 29, country: "South Korea" }
      ],
      tableCPLDiffData: [
        { id: 1, name: "John Doe", age: 25, country: "USA" },
        { id: 2, name: "Jane Smith", age: 30, country: "Canada" },
        { id: 3, name: "Sam Green", age: 35, country: "UK" },
        { id: 4, name: "Alice Brown", age: 28, country: "Australia" },
        { id: 5, name: "Michael Johnson", age: 40, country: "New Zealand" },
        { id: 6, name: "Chris Lee", age: 22, country: "Singapore" },
        { id: 7, name: "Patricia Kim", age: 29, country: "South Korea" }
      ],
      tableLeadsCPLDiffData: [
        { id: 1, name: "John Doe", age: 25, country: "USA" },
        { id: 2, name: "Jane Smith", age: 30, country: "Canada" },
        { id: 3, name: "Sam Green", age: 35, country: "UK" },
        { id: 4, name: "Alice Brown", age: 28, country: "Australia" },
        { id: 5, name: "Michael Johnson", age: 40, country: "New Zealand" },
        { id: 6, name: "Chris Lee", age: 22, country: "Singapore" },
        { id: 7, name: "Patricia Kim", age: 29, country: "South Korea" }
      ],
      dummyData: [
        {
          projectName: "Atlantis",
          dateWiseData: [{ date: "2024-05-23 - 2024-05-29" }]
        },
        {
          projectName: "Flora",
          dateWiseData: [{ date: "2024-05-23 - 2024-05-29" }]
        }
      ],
      seriesBar: [
        {
          name: "Quality",
          data: [
            
          ]
        },
        {
          name: "Volume",
          data: [

          ]
        }
      ],
      series: [
        // {
        //   name: "google",
        //   data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
        // },
        // {
        //   name: "meta",
        //   data: [
        //     8,
        //     15,
        //     20,
        //     150,
        //     130,
        //     165,
        //     20,
        //     180,
        //     190,
        //     85,
        //     210,
        //     65,
        //     150,
        //     100,
        //     120,
        //     30,
        //     25,
        //     10
        //   ]
        // },
        // {
        //   name: "amazon",
        //   data: [
        //     10,
        //     19,
        //     25,
        //     155,
        //     135,
        //     175,
        //     25,
        //     175,
        //     195,
        //     75,
        //     200,
        //     55,
        //     140,
        //     110,
        //     130,
        //     40,
        //     45,
        //     20
        //   ]
        // }
      ],
      chartBarOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [
            "Project 1",
            "Project 2",
            "Project 3",
          ]
        },
        yaxis: {
          title: {
            text: "Quality Vs Volume"
          }
        },
        fill: {
          opacity: 1
        }
      },
      chartOptions: {
        chart: {
          type: "line",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        forecastDataPoints: {
          count: 7
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        legend: {
          position: "right",
          offsetY: 80
        },
        yaxis: {
          title: {
            text: "Cost Per Lead"
          }
        },
        markers: {
          size: 4,
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              fillColor: "#ffffff",
              strokeColor: "#7ab240",
              size: 5
            }
          ],
          shape: "circle",
          radius: 2,
          hover: {
            size: 3
          }
        },
        colors:[
          "#C82925", // Orange
          "#72CB6B", // Lime Green
          "#5BC1EC", // Blue
          "#7A64B2", // Pink
          "#EEF03E", // Purple
          "#26FF97", // Aqua
          "#E260A9", // Yellow
          "#2B28AD", // Dark Pink
          "#D7811C", // Chartreuse
          "#B6219E", // Sky Blue
          "#066225", // Olive Green
          "#BDBBFF", // Orange Red
          "#625E0D", // Light Blue
          "#FF726E", // Magenta
          "#84A8A1", // Hot Pink
          "#20F5DC", // Medium Spring Green
          "#ADF21C", // Indigo
          "#4B1070", // Medium Sea Green
          "#C464FF", // Peach
          "#A8655C", // Light Sea Green
          "#486694", // Violet
          "#FA00FF", // Hot Pink
          "#0C3631", // Light Green
          "#F4A65F", // Light Pink
          "#51A0B9", // Crimson
          "#FF004D", // Cornflower Blue
          "#FF93BA", // Lime
          "#FF4D00", // Red
          "#33FF80", // Spring Green
          "#8000FF"  // Purple
        ],
        xaxis: {
          categories: [
            // "Feb",
            // "Mar",
            // "Apr",
            // "May",
            // "Jun",
            // "Jul",
            // "Aug",
            // "Sep",
            // "Oct"
          ]
        },
        title: {
          align: "left",
          style: {
            fontSize: "16px",
            color: "#666"
          }
        }
      }
    };
  },
  props: {
    trendOptionsType: {
      type: Object
    },
    trendsOverallData: {
      type: Array
    },
    leadsData:{
      type: Array
    },
    isLeadsDataFetched:{
      type: Boolean
    },
    ifFetchedTrends: {
      type: Boolean
    },
    ifFetchedTableDataTrends: {
      type: Boolean
    },
    ifClientFetchedTrends: {
      type: Boolean
    },
    tableTrendsData:{
      type: Array
    },
    qualityVolumeChartData:{
       type:Array
    },
    isQualityVolumeChartDataFetched:{
       type:Boolean
    },
    selectedDates:{
      type: String
    }
  },
  components: {
    BCard,
    BCol,
    BRow,
    apexchart: VueApexCharts,
    BCardTitle,
    VueGoodTable,
    BPagination,
    BFormSelect,
    multiChannelSpinner
  },
  watch: {
    trendsOverallData(newVal, oldVal) {
      this.trendsOverallData = newVal;
      this.updateTrendsGraph();
    },
    filterDataMetricWiseType(newVal, oldVal){
      this.filterDataMetricWiseType = newVal;
      this.updateTrendsGraph();
    },
    filterDataQualityVolumeWiseType(newVal, oldVal){
      this.filterDataQualityVolumeWiseType = newVal;
      this.updateGraphsForQuality()
    },
    qualityVolumeChartData(newVal, oldVal){
      this.qualityVolumeChartData = newVal
      this.updateGraphsForQuality();
    }
  },
  methods: {
    handleTrendsMetricsChange(newSelection) {
      this.$emit("sourceTrendsChangedData", newSelection);
    },
    updateTrendsGraph() {
      let allSeries = [];
      if (this.trendsOverallData && this.trendsOverallData.length > 0) {
        for (let i = 0; i < this.trendsOverallData.length; i++) {
          let cpclData = [];
          const project = this.trendsOverallData[i];

          if (
            project &&
            project.dateWiseData &&
            project.dateWiseData.length > 0
          ) {
            cpclData = project.dateWiseData.map(
              data => data.insights && data.insights[this.filterDataMetricWiseType.value]
             
            );
            allSeries.push({
              name: project.projectName,
              data: project.dateWiseData.map(
                data => data.insights && data.insights[this.filterDataMetricWiseType.value]
              )
            });
          } else {
            allSeries.push({
              name: project.projectName,
              data: []
            });
          }
        }

        let dates = [];
        if (
          this.trendsOverallData[0].dateWiseData &&
          this.trendsOverallData[0].dateWiseData.length > 0
        ) {
          dates = this.trendsOverallData[0].dateWiseData.map(data => data.date);
        }

        this.series = allSeries;
        if (Object.keys(dates).length === 1) {
          this.chartOptions = {
          chart: {
            type: "line",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          colors:[
          "#C82925", // Orange
          "#72CB6B", // Lime Green
          "#5BC1EC", // Blue
          "#7A64B2", // Pink
          "#EEF03E", // Purple
          "#26FF97", // Aqua
          "#E260A9", // Yellow
          "#2B28AD", // Dark Pink
          "#D7811C", // Chartreuse
          "#B6219E", // Sky Blue
          "#066225", // Olive Green
          "#BDBBFF", // Orange Red
          "#625E0D", // Light Blue
          "#FF726E", // Magenta
          "#84A8A1", // Hot Pink
          "#20F5DC", // Medium Spring Green
          "#ADF21C", // Indigo
          "#4B1070", // Medium Sea Green
          "#C464FF", // Peach
          "#A8655C", // Light Sea Green
          "#486694", // Violet
          "#FA00FF", // Hot Pink
          "#0C3631", // Light Green
          "#F4A65F", // Light Pink
          "#51A0B9", // Crimson
          "#FF004D", // Cornflower Blue
          "#FF93BA", // Lime
          "#FF4D00", // Red
          "#33FF80", // Spring Green
          "#8000FF"  // Purple
        ],
          markers: {
          size: 4,
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              fillColor: "#ffffff",
              strokeColor: "#7ab240",
              size: 5
            }
          ],
          shape: "circle",
          radius: 2,
          hover: {
            size: 3
          }
         },
          forecastDataPoints: {
            count: 7
          },
          stroke: {
            width: 2,
            curve: "smooth"
          },
          legend: {
            position: "right",
            offsetY: 0
          },
          xaxis: {
            categories: dates
          },
          yaxis: {
            title: {
              text: this.filterDataMetricWiseType.label,
              style: {
              fontSize: "13px",
              color: "#666",
            }
            }
          },
          
          title: {
            align: "left",
            style: {
              fontSize: "16px",
              color: "#666"
            }
          }
        };
        }else{
          this.chartOptions = {
          chart: {
            type: "line",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          colors:[
          "#C82925", // Orange
          "#72CB6B", // Lime Green
          "#5BC1EC", // Blue
          "#7A64B2", // Pink
          "#EEF03E", // Purple
          "#26FF97", // Aqua
          "#E260A9", // Yellow
          "#2B28AD", // Dark Pink
          "#D7811C", // Chartreuse
          "#B6219E", // Sky Blue
          "#066225", // Olive Green
          "#BDBBFF", // Orange Red
          "#625E0D", // Light Blue
          "#FF726E", // Magenta
          "#84A8A1", // Hot Pink
          "#20F5DC", // Medium Spring Green
          "#ADF21C", // Indigo
          "#4B1070", // Medium Sea Green
          "#C464FF", // Peach
          "#A8655C", // Light Sea Green
          "#486694", // Violet
          "#FA00FF", // Hot Pink
          "#0C3631", // Light Green
          "#F4A65F", // Light Pink
          "#51A0B9", // Crimson
          "#FF004D", // Cornflower Blue
          "#FF93BA", // Lime
          "#FF4D00", // Red
          "#33FF80", // Spring Green
          "#8000FF"  // Purple
        ],
          forecastDataPoints: {
            count: 7
          },
          stroke: {
            width: 2,
            curve: "smooth"
          },
          legend: {
            position: "right",
            offsetY: 0
          },
          xaxis: {
            categories: dates
          },
          yaxis: {
            title: {
              text: this.filterDataMetricWiseType.label,
              style: {
              fontSize: "13px",
              color: "#666",
            }
            }
          },
          
          title: {
            align: "left",
            style: {
              fontSize: "16px",
              color: "#666"
            }
          }
        };
        }
       
      }
    },
    updateGraphsForQuality(){
      if (this.qualityVolumeChartData && this.qualityVolumeChartData.length > 0) {
        let projectTypeName = this.qualityVolumeChartData.map(data => data.projectName)
        let allSeries = []
        const constantNames = ["Quality", "Volume"]
        for (let i = 0; i < constantNames.length; i++) {
        let seriesData = this.qualityVolumeChartData.map(data => data.subProductData[constantNames[i]][this.filterDataQualityVolumeWiseType.value]);
        allSeries.push({
          name: constantNames[i],
          data: seriesData
        });
      } 
        this.seriesBar = allSeries;
        this.chartBarOptions = {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: projectTypeName
        },
        yaxis: {
          title: {
            text: `${this.filterDataQualityVolumeWiseType.label}`
          }
        },
        fill: {
          opacity: 1
        }
      }

    }
   },
    updateCPLTrendPage(page) {
      this.currentCPLTrendPage = page;
    },
    updateCPLDiffPage(page) {
      this.currentCPLDiffPage = page;
    },
    updateLeadsCPLTrendPage(page) {
      this.currentLeadsCPLTrendPage = page;
    }
  },
  computed: {
    getAllName() {
      let arr = [];
      arr = this.trendsOverallData.map(i => {
        return i.projectName;
      });
      return arr;
    },
    // paginatedLeadsCPLTrendRows() {
    //   const start =
    //     (this.currentLeadsCPLTrendPage - 1) * this.pageLeadsCPLTrendLength;
    //   const end = start + this.pageCPLTrendLength;
    //   return this.tableLeadsCPLDiffData.slice(start, end);
    // },
    tableCPLTrendColumns() {
      return [
        { label: "Project Name", field: "projectName", tdClass: "text-center", thClass: "text-center" },
        { label: `Last 30 Days (${this.filterData30DaysMetricWiseType.label})`, field: "metrics.total", type: "number", tdClass: "text-center", thClass: "text-center" },
        { label: `Yesterday (${this.filterData30DaysMetricWiseType.label})`, field: "metrics.yesterday", tdClass: "text-center", thClass: "text-center" },
        { label: `Difference (${this.filterData30DaysMetricWiseType.label})`, field: "metrics.difference", tdClass: "text-center", thClass: "text-center" },
        { label: `Difference % (${this.filterData30DaysMetricWiseType.label})`, field: "metrics.differencePercent", tdClass: "text-center", thClass: "text-center" },
        // { label: `Target (${this.filterData30DaysMetricWiseType.label})`, field: "targetCPL", tdClass: "text-center", thClass: "text-center" },
      ];
    },
      filteredLeadsTableData() {
      this.tablesLeadsTrendsWiseData = this.leadsData;
      if (this.tableCPLTrendData.length === 0) return [];
      let res = this.tablesLeadsTrendsWiseData.map(item => {
        const metric = this.filterLeadsWiseType.value;
        const dateWiseData = item.subProductData;
        if (dateWiseData) {
          return {
            projectName: item.projectName,
            total: dateWiseData.total ? dateWiseData.total[metric] : 0,
            LeadGen: dateWiseData.LeadGen ? dateWiseData.LeadGen[metric] : 0,
            Discovery: dateWiseData.Discovery ? dateWiseData.Discovery[metric] : 0,
            Call: dateWiseData.Call ? dateWiseData.Call[metric] : 0,
            Search: dateWiseData.Search ? dateWiseData.Search[metric] : 0,
            LeadGen_Quality: dateWiseData.LeadGen_Quality ? dateWiseData.LeadGen_Quality[metric] : 0,
            LeadGen_Volume: dateWiseData.LeadGen_Volume ? dateWiseData.LeadGen_Volume[metric] : 0,
            Reach: dateWiseData.Reach ? dateWiseData.Reach[metric] : 0,
            PMax: dateWiseData.PMax ? dateWiseData.PMax[metric] : 0,
            Video: dateWiseData.Video ? dateWiseData.Video[metric] : 0,
            Display: dateWiseData.Display ? dateWiseData.Display[metric] : 0,
          };
        }
        return item;
      });

      return res;
    },
    filteredTableData() {
    this.tablesTrendsWiseData = this.tableTrendsData
      if (this.tableCPLTrendData.length === 0) return [];
      return this.tablesTrendsWiseData.map(item => {
        const metric = this.filterData30DaysMetricWiseType.value;
        const dateWiseData = item.dateWiseData;

        if (dateWiseData && dateWiseData.length >= 4) {
          return {
            projectName: item.projectName,
            metrics: {
              total: dateWiseData[0].insights[metric],
              yesterday: dateWiseData[1].insights[metric],
              difference: dateWiseData[2].insights[metric],
              differencePercent: dateWiseData[3].insights[metric],
            },
            targetCPL: "N/A",
          };
        }
        return item;
      });
    },
    paginatedCPLTrendRows() {
      const start = (this.currentCPLTrendPage - 1) * this.pageCPLTrendLength;
      const end = start + this.pageCPLTrendLength;
      return this.filteredTableData.slice(start, end);
    },
    paginatedCPLDiffRows() {
      const start = (this.currentCPLDiffPage - 1) * this.pageCPLDIffLength;
      const end = start + this.pageCPLDIffLength;
      return this.tableCPLDiffData.slice(start, end);
    },
    paginatedLeadsCPLTrendRows() {
      const start = (this.currentLeadsCPLTrendPage - 1) * this.pageLeadsCPLTrendLength;
      const end = start + this.pageLeadsCPLTrendLength;
      return this.filteredLeadsTableData.slice(start, end);
    }
  },
  mounted() {
    this.updateTrendsGraph();
    this.updateGraphsForQuality();
  }
};
</script>

<style type="scss">
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.custom-data-table-trends table {
  font-size: 11px !important;
}

.searchInput {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 210px !important;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}
.trendscharts .apexcharts-tooltip {
  min-height: 80px;
  max-height: 480px;
  margin-top: -67px !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  ::-webkit-scrollbar {
    display: none;
}
}

.trendscharts .apexcharts-tooltip .apexcharts-tooltip-series-group {
  max-height: 50px;
  overflow-y: auto; 
}


</style>

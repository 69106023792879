<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card style="margin-left: 5px;margin-right: 5px">
          <div v-if="isLoadCreatives" class="spinnerCentered">
            <multiChannelSpinner />
          </div>
          <div v-else>
            <div style="display: flex; justify-content: space-between;">
              <div>
                <b-card-title>
                  <h3 class="app-title mdsm">
                    <span style="display: none;"> {{ getOverAllData }}</span>
                    Creative Analysis 
                  </h3>
                </b-card-title>
              </div>
              <div
                style="display: flex; justify-content: space-between; gap: 20px;"
              >
                <div style="margin-top: -15px;">
                  <label>Projects:</label>
                  <b-form-group>
                    <v-select
                      :clearable="false"
                      @input="handleCreativeNameChange"
                      v-model="filterDataBasedProjectType"
                      :options="getAllName"
                      label="label"
                      class="project-analysis heightCreativesFixed"
                      style="text-transform: capitalize; font-size: 12px; width: 130px !important;"
                    />
                  </b-form-group>
                </div>
                <div style="margin-top: -15px;">
                  <label>Metric:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize; font-size: 12px ; width: 130px !important;"
                    v-model="filterDataMetricWiseType"
                    @input="handleCreativeMetricChange"
                  >
                    <option
                      v-for="(option, index) in getAllMetricsName"
                      :key="index"
                      :value="option"
                    >
                      <span>
                        {{ option.label }}
                      </span>
                    </option>
                  </b-form-select>
                </div>
                <div style="margin-top: -15px;">
                  <label>Channel:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize;"
                    :options="sourceType"
                    label="title"
                    v-model="filterDataBasedChannelType"
                    @input="handleFilterDataChannelType"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                  <!-- <label>Channel:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize; font-size: 12px ; width: 130px !important;"
                    v-model="filterDataBasedProjectType"
                  >
                    <option
                      v-for="(option, index) in getAllName"
                      :key="index"
                      :value="option"
                    >
                      <span>
                        {{ option }}
                      </span>
                    </option>
                  </b-form-select> -->
                </div>
                <div
                  style="margin-top: -15px;"
                  v-if="
                    this.filterDataBasedChannelType === 'googleAds' ||
                      this.filterDataBasedChannelType === 'meta'
                  "
                >
                  <label>Categories:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize;"
                    v-model="selectedCategories"
                    @input="handleFilterDataCategoryType"
                  >
                    <option
                      v-for="(option, index) in keyCategoryMapping"
                      :key="index"
                      :value="option"
                    >
                      <span>
                        {{ option.label }}
                      </span>
                    </option>
                  </b-form-select>
                  <!-- <label>Channel:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize; font-size: 12px ; width: 130px !important;"
                    v-model="filterDataBasedProjectType"
                  >
                    <option
                      v-for="(option, index) in getAllName"
                      :key="index"
                      :value="option"
                    >
                      <span>
                        {{ option }}
                      </span>
                    </option>
                  </b-form-select> -->
                </div>
              </div>
            </div>
            <b-col cols="12">
              <b-card>
                <div v-if="isLoadCreatives" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div v-else>
                  <div :disabled="this.filterDataBasedChannelType === 'meta' || this.filterDataBasedChannelType === 'googleAds'"
                    style="display: flex; width: 220px; margin-top: 10px; font-size: 11px; font-weight: bold; margin-left: 15px;"
                  >
                    <span
                      @click="toggleLegend('google')"
                      :class="{ opacity: !selectedLegends.includes('google') }"
                      style="width: 12px; height: 12px; background-color: #008FFB; border-radius: 5px; margin-right: 2px; cursor: pointer;"
                    ></span>
                    <span
                      @click="toggleLegend('google')"
                      style="margin-right: 4px; cursor: pointer;"
                      >Google</span
                    >

                    <span
                      @click="toggleLegend('meta')"
                      :class="{ opacity: !selectedLegends.includes('meta') }"
                      style="width: 12px; height: 12px; background-color: #00E396; border-radius: 5px; margin-right: 4px; cursor: pointer;"
                    ></span>
                    <span
                      @click="toggleLegend('meta')"
                      style="margin-right: 2px; cursor: pointer;"
                      >Meta</span
                    >
                  </div>
                  <div id="chart">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </div>
              </b-card>
            </b-col>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row> </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCard,
  BCol,
  BCardTitle,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
export default {
  data() {
    return {
      individualSeries: [],
      metaSeries: [],
      googleSeries: [],
      metaAdsAll: [],
      googleAdsAll: [],
      metaIndividual: [],
      googleIndividual: [],
      categoriesName: [],
      selectedLegends: ["google", "meta"],
      clickedLegents: "all",
      filterDataBasedProjectType: "",
      filterDataBasedChannelType: "all",
      sourceType: ["all", "googleAds", "meta"],
      selectedCategories: { label: "Language", value: "language" },
      keyCategoryMapping: [
        { label: "Language", value: "language" },
        { label: "Creative Type", value: "creativeType" },
        { label: "Video Duration", value: "videoDuration" },
        { label: "Video OrientationClicks", value: "videoOrientation" },
        { label: "Visual Cues", value: "visualCues" },
      ],
      filterDataMetricWiseType: { label: "CPL", value: "cpc" },
      getAllMetricsName: [
        { label: "Clicks", value: "clicks" },
        {
          label: "Impressions",
          value: "impressions",
        },
        { label: "Spends", value: "costMicros" },
        { label: "CPM", value: "cpm" },
        { label: "CPC", value: "cpcl" },
        { label: "CTR", value: "ctr" },
        { label: "Leads", value: "conversions" },
        { label: "CPL", value: "cpc" },
      ],
      series: [
        {
          name: "Google",
          data: [44000, 55000, 41000, 67000, 22000, 43000],
        },
        {
          name: "Meta",
          data: [48000, 50000, 40000, 65000, 25000, 40000],
        },
      ],
      seriesAll: [
        {
          name: "Q1 Budget",
          group: "budget",
          data: [44000, 55000, 41000, 67000, 22000, 43000],
        },
        {
          name: "Q1 Actual",
          group: "actual",
          data: [48000, 50000, 40000, 65000, 25000, 40000],
        },
        {
          name: "Q2 Budget",
          group: "budget",
          data: [13000, 36000, 20000, 8000, 13000, 27000],
        },
        {
          name: "Q2 Actual",
          group: "actual",
          data: [20000, 40000, 25000, 10000, 12000, 28000],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        dataLabels: {
          formatter: (val) => {
            return val / 1000 + "K";
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: [
            "Language",
            "Creative Type",
            "Video Duration",
            "Video Orientation",
            "Visual Cues",
          ],
        },
        yaxis: {
              title: {
                text: `-`
              }
            },
        fill: {
          opacity: 1,
        },
        colors: ["#008FFB", "#00E396"],
        yaxis: {
          labels: {
            formatter: (val) => {
              return val / 1000 + "K";
            },
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BFormSelect,
    apexchart: VueApexCharts,
    multiChannelSpinner,
    vSelect,
    BFormGroup,
  },
  props: {
    trendsOverallData: {
      type: Array,
    },
    isLoadCreatives: {
      type: Boolean,
    },
    clientEnabledData: {
      type: Array,
    },
    optionsList: {
      type: Array,
    },
    optionsListFlag: {
      type: Boolean,
    },
    clientId: {
      type: String,
    },
    creativesData: {
      type: Boolean,
    },
    graphData: {
      type: null,
    },
  },
  methods: {
    handleFilterDataCategoryType(newSelection) {
      console.log(newSelection);
    },
    handleCreativeNameChange(newSelection) {
      this.$emit("changedCreativesProjectName", newSelection);
    },
    handleCreativeMetricChange(newSelection) {
      this.$emit("changedCreativeMetricChange", newSelection);
      this.updateCreativesGraphs();
    },
    handleChannelChange(newSelection) {
      this.$emit("changeChannelChange", newSelection);
    },
    handleFilterDataChannelType(item) {
      this.$emit("changeChannelChange", item);
      this.filterDataBasedChannelType = item;
      this.selectedLegends = [];
      if (this.filterDataBasedChannelType === "googleAds") {
        this.selectedLegends.push("google");
      } else if (this.filterDataBasedChannelType === "meta") {
        this.selectedLegends.push("meta");
      } else {
        this.selectedLegends.push("google", "meta");
      }
      this.updateCreativesGraphs();
    },
    toggleLegend(type) {
      this.selectedLegends = this.selectedLegends || [];
      const index = this.selectedLegends.indexOf(type);
      if (index > -1) {
        this.selectedLegends.splice(index, 1);
      } else {
        this.selectedLegends.push(type);
      }
      this.updateCreativesGraphs();
    },
    updateCreativesGraphs() {
      const commonOptions = {
        chart: {
          type: "bar",
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        dataLabels: {
          formatter: (val) => `${val}`,
        },
        plotOptions: {
          bar: { horizontal: false },
        },
        fill: { opacity: 1 },
        yaxis: {
              title: {
                text: `${this.filterDataMetricWiseType.label.toUpperCase()}`
              }
          },
        legend: { show: false },
      };

      let series = [];
      const colors = [];

      if (this.filterDataBasedChannelType === "all") {
        series = [
          { name: "Google", data: this.googleAdsAll },
          { name: "Meta", data: this.metaAdsAll },
        ];
        colors.push("#008FFB", "#00E396");
      } else if (this.filterDataBasedChannelType === "googleAds") {
        series = [{ name: "Google", data: this.googleIndividual }];
        colors.push("#008FFB");
      } else if (this.filterDataBasedChannelType === "meta") {
        series = [{ name: "Meta", data: this.metaIndividual }];
        colors.push("#00E396");
      }

      this.series = series;
      let axis = [];
      if (this.filterDataBasedChannelType === "all") {
        axis = [
          "Language",
          "Creative Type",
          "Video Duration",
          "Video Orientation",
          "Visual Cues",
        ];
      } else if (this.filterDataBasedChannelType === "meta") {
        axis = this.metaSeries;
      } else if (this.filterDataBasedChannelType === "googleAds") {
        axis = this.googleSeries;
      }
      this.chartOptions = {
        ...commonOptions,
        chart: {
          ...commonOptions.chart,
        },
        xaxis: {
          categories: axis,
        },
        colors: colors,
      };
    },
  },
  computed: {
    getOverAllData() {
      this.languageArr = [];
      this.creativeTypeArr = [];
      this.videoDurationArr = [];
      this.videoOrientationArr = [];
      this.visualCuesArr = [];
      this.metaAdsAll = [];
      this.googleAdsAll = [];
      this.metaIndividual = [];
      this.googleIndividual = [];
      this.individualSeries = [];
      this.metaSeries = [];
      this.googleSeries = [];

      if (this.filterDataBasedChannelType === "meta") {
        this.metaSeries = [];
        let arr = [],
          demo = [];
        if (this.graphData && this.graphData.meta) {
          const metaKeys = Object.keys(
            this.graphData.meta[this.selectedCategories.value]
          );
          for (let i = 0; i < metaKeys.length; i++) {
            const key = metaKeys[i];
            if (key !== "total") {
              this.metaSeries.push(key);
              let res = this.graphData.meta[this.selectedCategories.value][key];
              demo.push(res[this.filterDataMetricWiseType.value]);
              arr.push(key);
            }
            arr.push(key);
          }
        }
        arr.forEach((i) => {
          if (i !== "total") {
            this.individualSeries.push(i);
          }
        });
        this.metaIndividual = demo;
      }
      if (this.filterDataBasedChannelType === "googleAds") {
        this.individualSeries = [];
        let arr = [],
          demo = [];
        if (this.graphData && this.graphData.googleAds) {
          const metaKeys = Object.keys(
            this.graphData.googleAds[this.selectedCategories.value]
          );
          for (let i = 0; i < metaKeys.length; i++) {
            const key = metaKeys[i];
            if (key !== "total") {
              let res = this.graphData.googleAds[this.selectedCategories.value][
                key
              ];
              demo.push(res[this.filterDataMetricWiseType.value]);
              arr.push(key);
            }
          }
        }
        arr.forEach((i) => {
          if (i !== "total") {
            this.googleSeries.push(i);
          }
        });
        this.googleIndividual = demo;
      }
      if (this.selectedLegends.length >= 2) {
        if (this.graphData && this.graphData.meta) {
          const metaKeys = Object.keys(this.graphData.meta);
          for (let index = 0; index < metaKeys.length; index++) {
            const key = metaKeys[index];
            this.metaAdsAll.push(
              this.graphData.meta[key].total[
                this.filterDataMetricWiseType.value
              ]
                ? this.graphData.meta[key].total[
                    this.filterDataMetricWiseType.value
                  ]
                : 0
            );
            this.googleAdsAll.push(
              this.graphData.googleAds[key].total[
                this.filterDataMetricWiseType.value
              ]
                ? this.graphData.googleAds[key].total[
                    this.filterDataMetricWiseType.value
                  ]
                : 0
            );
          }
        }
      }
      // if (this.selectedLegends.includes("google")) {
      //   if (this.graphData && this.graphData.googleAds) {
      //   const metaKeys = Object.keys(this.graphData.googleAds);
      //   const storedKeys = [];
      //   for (let index = 0; index < metaKeys.length; index++) {
      //     const key = metaKeys[index];
      //     storedKeys.push(key);
      //     console.log(this.graphData.googleAds[key],'---');
      //     // const key = metaKeys[index];
      //     // this.metaAdsAll.push(this.graphData.meta[key].total[this.filterDataMetricWiseType.value] ? this.graphData.meta[key].total[this.filterDataMetricWiseType.value] : 0)
      //     // this.googleAdsAll.push(this.graphData.googleAds[key].total[this.filterDataMetricWiseType.value] ? this.graphData.googleAds[key].total[this.filterDataMetricWiseType.value] : 0)
      //   }
      //   console.log(storedKeys);
      // }
      // }
      if (this.graphData && this.graphData.googleAds) {
        const keyMapping = {
          language: "Language",
          creativeType: "Creative Type",
          videoDuration: "Video Duration",
          videoOrientation: "Video Orientation",
          visualCues: "Visual Cues",
        };
        this.categoriesName = Object.keys(this.graphData.googleAds).map(
          (key) => keyMapping[key] || key
        );
        this;
      } else {
        this.categoriesName = []; // or handle it accordingly
      }
      let arr = this.categoriesName;
      return arr;
    },
    getAllName() {
      this.filterDataBasedProjectType = this.optionsList[0];
      return this.optionsList;
    },
  },
  watch: {
    googleAdsAll(newVal, oldVal) {
      this.googleAdsAll = newVal;
      this.updateCreativesGraphs();
    },
    metaAdsAll(newVal, oldVal) {
      this.metaAdsAll = newVal;
      this.updateCreativesGraphs();
    },
    clickedLegents(newVal, oldVal) {
      this.clickedLegents = newVal;
      this.updateCreativesGraphs();
    },
  },
  mounted() {
    this.clickedLegents = "all";
    this.filterDataBasedProjectType = this.optionsList[0];
    this.updateCreativesGraphs();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.opacity {
  opacity: 0.6;
}
.project-analysis {
  .vs__dropdown-toggle {
    padding: 1.59px 0 7px 0 !important;
  }
}

.heightCreativesFixed{
  .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    font-size: 12px !important;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    height: 38px !important;
}
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Set the height of the container */
}
</style>

<template>
  <div>
    <div
      v-if="this.idClientEntireLoad"
      class="spinnerCentered"
      style="margin-top: 15%;"
    >
      <Spinner />
    </div>
    <div
      v-else
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      class="multi-dashboard-overlay"
    >
      <!-- :show="showSummaryDashboardLoading ||
      showSummaryDashboardLoadingGraph ||
      isLiveDataIsLoad " -->
      <b-row v-if="client.status" class="content-header">
        <b-col class="content-header-left mb-2" cols="8">
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0 text-white">
                <b-avatar
                  class="mr-1"
                  size="32"
                  :src="client.logo"
                  :text="avatarText(client.name)"
                  :variant="client.logo ? `white` : `primary`"
                />
                {{ client.name }}
              </h2>
              <div class="breadcrumb-wrapper breadcrumb-container text-white">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon
                      icon="HomeIcon"
                      size="16"
                      class="align-text-top text-white"
                    />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    v-for="item in breadcrumbs"
                    :key="item.text"
                    :active="item.active"
                    :to="item.to"
                  >
                    <span class="text-white">
                      {{ item.text }}
                    </span>
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col cols="4" style="margin-bottom: 10px;">
          <div style="float: right;" v-if="client._id === '65f836e2dadfc2dc9b4b913c'">
            <a :href="`https://docs.google.com/spreadsheets/d/${client.googleSheetId}`" target="_blank"
              style="text-decoration: none; color: white;">
              <b-button variant="primary">
                <img src="@/assets/images/logo/sheets.png" alt="Icon"
                  style="width: 30px; height: 30px; margin-right: 5px;">
                <span style="font-size: 12px;"> View Daily Report</span>
              </b-button>
            </a>
          </div>
        </b-col> -->
      </b-row>

      <b-row class="filterContainer">
        <b-col cols="4">
          <b-card class="headerContainerItem filterCard">
            <div>
              <b-row class="allChannelContainer">
                <b-col cols="12" :style="dynamicStyle">
                  <label
                    class="multi-range-select"
                    v-if="this.reportModeSelected == 'weekly'"
                  >
                    Showing Weekly Data</label
                  >
                  <label
                    class="multi-range-select"
                    v-else-if="this.reportModeSelected == 'monthly'"
                  >
                    Showing Monthly Data</label
                  >
                  <label
                    class="multi-range-select"
                    v-else-if="
                      this.tabMultiChannelName === 'ProductOutCome' &&
                        this.reportModeSelected === 'weekly'
                    "
                  >
                    Showing Weekly Outcome Data</label
                  >
                  <label
                    class="multi-range-select form-label"
                    v-else
                    for="clientSelect"
                    >Showing Data for {{ this.dateRange.startDate | date }} to
                    {{ this.dateRange.endDate | date }}</label
                  >
                  <date-range-picker
                    ref="picker"
                    opens="right"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :showWeekNumbers="false"
                    :showDropdowns="true"
                    :autoApply="true"
                    v-model="dateRange"
                    @update="updateValues"
                    :dateFormat="dateFormat"
                    control-container-class="picker-controller"
                    :ranges="dateRanges"
                    :auto-apply="false"
                    :readonly="false"
                    :maxDate="
                      new Date(new Date().setDate(new Date().getDate() - 1))
                    "
                    class="filterCardSelect multiDateSelector"
                    :disabled="
                      this.reportModeSelected == 'weekly' ||
                        this.reportModeSelected == 'monthly' ||
                        tabMultiChannelName === 'ProductOutCome'
                    "
                    :class="{
                      disabledRanges:
                        reportModeSelected == 'weekly' ||
                        reportModeSelected == 'monthly' ||
                        tabMultiChannelName === 'ProductOutCome',
                    }"
                  >
                    <template v-slot:input="picker">
                      <feather-icon
                        icon="CalendarIcon"
                        size="16"
                        class="mr-1"
                      ></feather-icon>
                      {{ formatDateDisplay(picker.startDate) }} -
                      {{ formatDateDisplay(picker.endDate) }}
                    </template>
                  </date-range-picker>
                </b-col>
                <b-col
                  cols="12"
                  v-if="
                    this.tabMultiChannelName === 'ZoneInsights' ||
                      this.tabMultiChannelName === 'ProductOutCome'
                  "
                >
                  <div
                    style="display: flex; gap: 10px; margin-top: 5px; justify-content:space-between;"
                  >
                    <label style="display: flex; align-items: center;">
                      <input
                        :disabled="tabMultiChannelName === 'ProductOutCome'"
                        class="checkbox-weekly"
                        style="cursor: pointer;"
                        type="radio"
                        v-model="reportModeSelected"
                        value="daily"
                        @change="handleReportModeChange"
                      />
                      <span style="margin-left: 3px; margin-top:2px"
                        >Daily</span
                      >
                    </label>
                    <label style="display: flex; align-items: center;">
                      <input
                        :disabled="tabMultiChannelName === 'ProductOutCome'"
                        class="checkbox-weekly"
                        style="cursor: pointer;"
                        type="radio"
                        v-model="reportModeSelected"
                        value="weekly"
                        @change="handleReportModeChange"
                      />
                      <span style="margin-left: 3px; margin-top:2px"
                        >Weekly</span
                      >
                    </label>
                    <label style="display: flex; align-items: center;">
                      <input
                        class="checkbox-weekly"
                        style="cursor: pointer;"
                        type="radio"
                        v-model="reportModeSelected"
                        value="monthly"
                        @change="handleReportModeChange"
                      />
                      <span style="margin-left: 3px; margin-top:2px"
                        >Monthly</span
                      >
                    </label>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card
            style="height: 100px; "
            class="filterCard click-link multichannelCardfilter"
          >
            <div v-if="client.googleSheetReportEnabled">
              <b-row class="ml-1">
                <b-col :cols="getReportsCols">
                  <div class="form-group">
                    <label for="monthSelect" class="form-label">
                      Reports:</label
                    >
                    <div class="select-container">
                      <b-form-select
                        id="monthSelect"
                        v-model="selectedReportType"
                        @change="updateReportID"
                      >
                        <option
                          v-for="report in reportType"
                          :value="report"
                          :key="report"
                        >
                          <span style="font-size: 10px !important;">{{
                            report
                          }}</span>
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col
                  :cols="getReportsCols"
                  v-if="this.selectedReportType == 'Daily'"
                >
                  <div class="form-group">
                    <label for="monthSelect" class="form-label"> Month:</label>
                    <div class="select-container">
                      <b-form-select
                        id="monthSelect"
                        v-model="selectedReportMonth"
                        :disabled="this.selectedReportType == 'Weekly'"
                        @change="updateReportID"
                        class="custom-report-select"
                      >
                        <option
                          v-for="month in dynamicArray"
                          :value="month.value"
                          :key="month.value"
                        >
                          {{ month.label }}
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col :cols="getReportsCols">
                  <div class="form-group">
                    <label for="monthSelect" class="form-label"> year:</label>
                    <div class="select-container">
                      <b-form-select
                        disabled
                        id="monthSelect"
                        v-model="selectedYear"
                      >
                        <option v-for="year in year" :value="year" :key="year">
                          {{ year }}
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="2">
                  <a
                    :href="
                      `https://docs.google.com/spreadsheets/d/${this.selectedReportID}`
                    "
                    target="_blank"
                    style="text-decoration: none; color: white;"
                  >
                    <div variant="primary">
                      <img
                        src="@/assets/images/logo/sheets.png"
                        alt="Icon"
                        style="width: 30px; height: 30px; margin-right: 5px;margin-top: 27px; "
                      />
                      <span style="font-size: 12px;"></span>
                    </div>
                  </a>
                </b-col>
                <b-col cols="12" style="margin-top: -12px !important;">
                  <a
                    :href="
                      `https://docs.google.com/spreadsheets/d/${this.selectedReportID}`
                    "
                    target="_blank"
                  >
                    <span
                      style="text-decoration: underline; margin-left: 20%;
                  color: #6b6b6b !important; font-size: 12px"
                    >
                      View google sheet report here
                    </span>
                  </a>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-link :to="`/client/${client.slug}`">
                <b-card-text class="xs text-center text-capitalize mt-1">
                  <span
                    style="text-decoration: underline;
                  color: #6b6b6b !important;"
                  >
                    click here to connect your data
                  </span>
                </b-card-text>
              </b-link>
            </div>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card style="height: 100px; " class="filterCard filterCardIcons">
            <div
              style="display: flex;  align-items: center; justify-content: space-evenly; margin-top: 8px;"
              class="iconGroupContainer"
            >
              <!-- Loop through computed properties to generate links -->
              <div
                v-for="link in orderedLinks()"
                :key="link.name"
                class="avatar-section"
              >
                <b-link
                  :to="link.to"
                  :disabled="link.disabled"
                  v-bind:style="{ opacity: link.connected ? 1 : 0.4 }"
                  v-bind:class="{ 'not-allowed': !link.connected }"
                >
                  <b-avatar
                    v-if="link.name == 'googleAds'"
                    square
                    variant="white"
                    size="45"
                    src="../../assets/images/logo/ads-logo.png"
                  ></b-avatar>
                  <b-avatar
                    v-if="link.name == 'amazonAds'"
                    square
                    variant="white"
                    size="45"
                    src="../../assets/images/logo/amazon.png"
                  ></b-avatar>
                  <b-avatar
                    v-if="link.name == 'facebookAds'"
                    square
                    variant="white"
                    size="45"
                    src="../../assets/images/logo/meta.png"
                  ></b-avatar>
                  <b-avatar
                    v-if="link.name == 'googleAnalytics'"
                    square
                    variant="white"
                    size="45"
                    src="../../assets/images/portrait/small/analytics-avatar.png"
                  ></b-avatar>
                  <b-avatar
                    v-if="link.name == 'gmb'"
                    square
                    variant="white"
                    size="45"
                    src="../../assets/images/logo/gmb.jpeg"
                  ></b-avatar>
                  <b-avatar
                    v-if="link.name == 'shopifyAds'"
                    square
                    variant="white"
                    size="45"
                    src="../../assets/images/logo/Shopify.png"
                  ></b-avatar>
                </b-link>
              </div>
            </div>
            <div
              v-if="client.googleSheetReportEnabled"
              style="margin-top: -12px !important;"
            >
              <b-link :to="`/client/${client.slug}`">
                <b-card-text class="xs text-center text-capitalize mt-1">
                  <span
                    style="text-decoration: underline;
                  color: #6b6b6b !important; font-size: 12px"
                  >
                    click here to connect your data
                  </span>
                </b-card-text>
              </b-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card class="tabs-container screenAlignmentMultiChannel">
        <b-tabs fill class="multichanneldashbpa">
          <b-tab title="Overview" active @click="changeTab('Overview')"></b-tab>
          <b-tab
            @click="changeTab('ProjectInsights')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span :class="isProjectWiseIsLoad ? 'text-red blink-soft' : ''"
                >Project Insights</span
              >
            </template>
          </b-tab>
          <b-tab
            @click="changeTab('ProductOutCome')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span
                :class="fetchProductOutWiseData ? 'text-red blink-soft' : ''"
                >Project Outcome</span
              >
            </template>
          </b-tab>
          <b-tab
            @click="changeTab('AffiliateInsights')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span
                :class="fetchAffilatestOutWiseData ? 'text-red blink-soft' : ''"
                >Affiliate Insights</span
              >
            </template>
          </b-tab>
          <b-tab
            @click="changeTab('ZoneInsights')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span
                :class="zoneWiseTypeOptionsFlag ? 'text-red blink-soft' : ''"
                >Group Insights</span
              >
            </template>
          </b-tab>
          <b-tab
            @click="changeTab('TargetVSAchieved')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span>Target/Achieved</span>
            </template>
          </b-tab>
          <b-tab
            title="Product Insights"
            @click="changeTab('ProductInsights')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span :class="isProductDatafetched ? 'text-red blink-soft' : ''"
                >Product Insights</span
              >
            </template>
          </b-tab>
          <b-tab
            title="Trends"
            @click="changeTab('Trends')"
            v-if="
              client.accessConfiguration &&
                client.accessConfiguration.projects &&
                client.accessConfiguration.projects.enabled
            "
          >
            <template #title>
              <span>
                <!-- <b-icon icon="graph-up" font-scale="1.5" color="#41B06E" class="dotLive" /> -->
                Trends</span
              >
            </template>
          </b-tab>
          <!-- <b-tab title="Creative Analysis" @click="changeTab('CreativeAnalysis')" v-if="client.accessConfiguration && client.accessConfiguration.projects && client.accessConfiguration.projects.enabled">
          </b-tab> -->
          <b-tab
            title="Campaign Overview"
            @click="changeTab('ChannelPerformanceOverview')"
          >
          </b-tab>
        </b-tabs>
      </b-card>
      <b-row
        class="piechartAndSpendsContainer"
        v-if="this.tabMultiChannelName === 'Overview'"
      >
        <b-col cols="8">
          <div class="mainPieChartContainer">
            <b-card class="mainPieChartCardContainer">
              <div>
                <b-row class="centered-form donutChartContainer">
                  <b-col cols="2">
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div>
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              Spends
                            </h5>
                            <h3
                              style="font-size:11px; color:#200E6B; font-weight:bold"
                            >
                              {{ formatNumberToTwoDecimals(this.spendsTotal) }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart
                          class="benchmarkPie"
                          type="donut"
                          :options="chartOptions"
                          :series="seriesSpends"
                          height="95"
                        ></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div>
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder ">
                              Impressions
                            </h5>
                            <h3
                              style="font-size:11px; color:#200E6B; font-weight:bold"
                            >
                              {{
                                formatNumberToTwoDecimals(this.impressionsTotal)
                              }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart
                          class="benchmarkPie"
                          type="donut"
                          :options="chartOptions"
                          :series="seriesImpressions"
                          height="95"
                        ></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div>
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              Clicks
                            </h5>
                            <h3
                              style="font-size:11px; color:#200E6B; font-weight:bold;"
                            >
                              {{ formatNumberToTwoDecimals(this.clicksTotal) }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart
                          class="benchmarkPie"
                          type="donut"
                          :options="chartOptions"
                          :series="seriesClicks"
                          height="95"
                        ></apexchart>
                      </div>
                      <div
                        style="display: flex;  width: 220px; margin-top:10px; font-size: 11px; font-weight: bold; margin-left: -50px;"
                      >
                        <span
                          v-if="this.googleAdsConnected"
                          style="width:12px;  height: 12px; background-color: #7ab240; border-radius: 5px; margin-right: 2px;"
                        ></span
                        ><span
                          v-if="this.googleAdsConnected"
                          style="margin-right: 4px;"
                          >Google</span
                        >
                        <span
                          v-if="this.amazonAdsConnected"
                          style="width:12px; height: 12px; background-color: #ff9900; border-radius: 5px; margin-right: 4px;"
                        ></span>
                        <span
                          v-if="this.amazonAdsConnected"
                          style="margin-right: 2px;"
                          >Amazon</span
                        >
                        <span
                          v-if="this.facebookAdsConnected"
                          style="width:12px; height: 12px; background-color: #0273eb; border-radius: 5px; margin-right: 4px;"
                        ></span
                        ><span
                          v-if="this.facebookAdsConnected"
                          style="margin-right: 2px;"
                          >Meta</span
                        >
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    cols="2"
                    v-if="client._id !== '6696476bc3cd30a4b4a7fc85'"
                  >
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div>
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              <span v-if="client.businessType[0] == 'leadgen'"
                                >Leads
                                <b-icon
                                  icon="info-circle-fill"
                                  font-scale="0.8"
                                  color="#68becc

"
                                  class="infoIconLive"
                                  v-b-tooltip.hover.top.html="
                                    '<div class=\'custom-tooltip\'>• Leads<br>• Messaging conversations initiated</div>'
                                  "
                              /></span>
                              <span
                                v-else-if="
                                  client.businessType[0] == 'ecommerce'
                                "
                                >Purchases</span
                              >
                              <span v-else>Conversions</span>
                            </h5>
                            <h3
                              v-if="client.businessType[0] == 'ecommerce'"
                              style="font-size:11px; color:#200E6B; font-weight:bold"
                            >
                              {{
                                formatNumberToTwoDecimals(
                                  this.webPurchasesTotal
                                )
                              }}
                            </h3>
                            <h3
                              v-else
                              style="font-size:11px; color:#200E6B; font-weight:bold"
                            >
                              {{
                                formatNumberToTwoDecimals(this.conversionsTotal)
                              }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart
                          v-if="client.businessType[0] == 'ecommerce'"
                          type="donut"
                          class="benchmarkPie"
                          :options="chartOptions"
                          :series="seriesWebPurchases"
                          height="95"
                        ></apexchart>
                        <apexchart
                          v-else
                          type="donut"
                          class="benchmarkPie"
                          :options="chartOptions"
                          :series="seriesConversions"
                          height="95"
                        ></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    cols="2"
                    v-if="
                      client.businessType[0] === 'leadgen' &&
                        client._id !== '6696476bc3cd30a4b4a7fc85'
                    "
                  >
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div>
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                CPL
                              </h5>
                              <h3
                                style="font-size:11px; color:#200E6B; font-weight:bold"
                              >
                                {{ this.cplTotal }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart
                            type="donut"
                            class="benchmarkPie"
                            :options="chartOptions"
                            :series="seriesCPL"
                            height="95"
                          ></apexchart>
                        </div>
                      </div>
                      <!-- <div v-else>
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                CPL
                              </h5>
                              <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                                {{ formatNumberToTwoDecimals(this.revenueTotal) }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart type="donut" class="benchmarkPie" :options="chartOptions" :series="seriesRevenue"
                            height="95"></apexchart>
                        </div>
                      </div> -->
                    </div>
                  </b-col>
                  <b-col cols="2" v-if="client.businessType[0] !== 'leadgen'">
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          isRevenueEmpty
                          // && this.metaRevenue > 0  && this.amazonRevenue > 0 && this.googleRevenue > 0
                        "
                      >
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                Revenue
                              </h5>
                              <h3
                                style="font-size:11px; color:#200E6B; font-weight:bold"
                              >
                                {{ this.revenueTotal }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart
                            type="donut"
                            class="benchmarkPie"
                            :options="chartOptions"
                            :series="seriesRevenue"
                            height="95"
                          ></apexchart>
                        </div>
                      </div>
                      <div v-else>
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                Revenue
                              </h5>
                              <h3
                                style="font-size:11px; color:#200E6B; font-weight:bold"
                              >
                                {{
                                  formatNumberToTwoDecimals(this.revenueTotal)
                                }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart
                            type="donut"
                            class="benchmarkPie"
                            :options="chartOptions"
                            :series="seriesRevenue"
                            height="95"
                          ></apexchart>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <div
                      v-if="this.showSummaryDashboardLoadingGraph"
                      class="spinnerCentered"
                    >
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div>
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                CTR
                              </h5>
                              <h3
                                style="font-size:11px; color:#200E6B; font-weight:bold"
                              >
                                {{ this.ctrTotal }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart
                            type="donut"
                            class="benchmarkPie"
                            :options="chartOptions"
                            :series="seriesCTR"
                            height="95"
                          ></apexchart>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
        <b-col cols="4">
          <div class="spendsTrackerContainer">
            <b-card style="min-height: 120px" class="mainPieChartCardContainer">
              <div
                v-if="isSpendsLoading"
                class="spinnerCentered"
                style="height: 120px;"
              >
                <multiChannelSpinner class="mt-1" />
              </div>
              <div v-else>
                <div style="display: flex; justify-content: space-between">
                  <span>
                    <b-icon
                      icon="circle-fill"
                      font-scale="1"
                      color="#41B06E"
                      class="dotLive"
                    /><span class="blink_text"
                      ><strong>Live</strong></span
                    ></span
                  >
                  <b-button variant="primary" @click="openAddSpendsModal">
                    <feather-icon
                      icon="PlusCircleIcon"
                      color="white"
                      size="15"
                      style="margin-right: 5px;"
                    />
                    Track
                  </b-button>
                </div>
                <div class="spendsCardTrackerContainer">
                  <div
                    v-if="this.spendsMessage && this.spendsMessage.length > 0"
                    style="display: flex; justify-content: space-between; margin-bottom: 0px;margin-top: 14px; margin-left: 15px"
                  >
                    <!-- <b-badge variant="primary">{{this.spendsMessage}}</b-badge> -->
                    <span class="animated-message-text">
                      <span>{{ this.spendsMessage }}</span>
                    </span>
                  </div>
                  <div
                    v-else
                    style="display: flex; justify-content: space-between; margin-bottom:10px; margin-left: 15px;"
                  >
                    <span
                      v-if="this.spendsTracker.trackingStartedFrom === null"
                      style="font-size: 13px; font-weight: bold; margin-bottom: 10px;margin-top: 8px;"
                      class="animated-message-text"
                      ><span>Add a transaction to start spends tracking!</span>
                    </span>
                    <span
                      v-else
                      style="font-size: 13px; font-weight: bold; margin-top: 0px;"
                      >Tracking Spends from
                      {{
                        new Date(
                          this.spendsTracker.trackingStartedFrom
                        ).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })
                      }}
                    </span>
                  </div>
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      <ul
                        style="text-decoration: none; list-style: none; margin-left: -25px; font-size: 14px"
                      >
                        <li>
                          <strong>Amount Received : </strong
                          >{{
                            (this.spendsTracker &&
                              this.spendsTracker.amountReceived) ||
                              this.conditionalSpends
                          }}
                        </li>
                        <li>
                          <strong>Total Spends : </strong
                          >{{
                            this.spendsTracker.totalSpends == undefined
                              ? "____"
                              : this.spendsTracker.totalSpends
                          }}
                        </li>
                        <li>
                          <strong>Available Balance : </strong
                          >{{
                            this.spendsTracker.balance == undefined
                              ? "____"
                              : this.spendsTracker.balance
                          }}
                        </li>
                        <li>
                          <strong>Est. Days Left : </strong
                          ><span>
                            {{
                              this.spendsTracker.daysLeft == undefined
                                ? "N/A"
                                : this.spendsTracker.daysLeft
                            }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <img
                        src="@/assets/images/logo/spending-money.png"
                        alt="d2scale-logo"
                        style="width: 60px; margin-right: 15px"
                      />
                    </div>
                  </div>
                  <div
                    style="display: flex; justify-content: space-evenly; margin-bottom: 0px"
                  >
                    <div style="display: flex; justify-content: space-between;">
                      <div>
                        <div v-if="this.googleAdsConnected">
                          <b-link
                            :to="`/client/${client.slug}/dashboard/google-ads`"
                          >
                            <b-avatar
                              square
                              variant="white"
                              size="40"
                              src="../../assets/images/logo/ads-logo.png"
                            >
                            </b-avatar>
                          </b-link>
                        </div>
                        <div v-else>
                          <b-avatar
                            square
                            variant="white"
                            size="40"
                            src="../../assets/images/logo/ads-logo.png"
                          >
                          </b-avatar>
                        </div>
                      </div>
                      <div>
                        <span
                          style="font-size: 13px; font-weight: bold; margin-top: 10px;"
                          >Google Ads</span
                        >
                        <br />
                        <span style="font-size: 14px;">
                          {{
                            this.spendsTracker.spends &&
                              this.spendsTracker.spends.google
                          }}
                        </span>
                      </div>
                      <br />
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <div>
                        <div v-if="this.facebookAdsConnected">
                          <b-link
                            :to="`/client/${client.slug}/dashboard/meta-ads`"
                          >
                            <b-avatar
                              square
                              variant="white"
                              size="40"
                              src="../../assets/images/logo/meta.png"
                            >
                            </b-avatar>
                          </b-link>
                        </div>
                        <div v-else>
                          <b-avatar
                            square
                            variant="white"
                            size="40"
                            src="../../assets/images/logo/meta.png"
                          >
                          </b-avatar>
                        </div>
                      </div>
                      <div>
                        <span
                          style="font-size: 13px; font-weight: bold; margin-top: 10px;"
                          >Meta</span
                        >
                        <br />
                        <span style="font-size: 14px;">
                          {{
                            this.spendsTracker.spends &&
                              this.spendsTracker.spends.meta
                          }}
                        </span>
                      </div>
                      <br />
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <div>
                        <div>
                          <b-avatar
                            square
                            variant="white"
                            size="40"
                            src="../../assets/images/logo/money-bags.png"
                          >
                          </b-avatar>
                        </div>
                      </div>
                      <div>
                        <span
                          style="font-size: 13px; font-weight: bold; margin-top: 10px;"
                          >Others</span
                        >
                        <br />
                        <span style="font-size: 14px;">
                          {{
                            this.spendsTracker.spends &&
                              this.spendsTracker.spends.amazon
                          }}
                        </span>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <div v-if="this.tabMultiChannelName === 'Overview'">
        <b-row class="centered-form">
          <b-col :cols="getCols" v-if="this.googleAdsConnected">
            <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer " v-else>
                  <span class="todayText">
                    <b-icon
                      icon="circle-fill"
                      font-scale="1"
                      color="#41B06E"
                      class="dotLive"
                    /><span class="blink_text">Today</span></span
                  >
                  <div
                    class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;"
                  >
                    <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
                      <b-avatar
                        square
                        variant="white"
                        size="45"
                        src="../../assets/images/logo/ads-logo.png"
                      >
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-0.5">
                      Google Ads
                    </b-card-text>
                  </div>
                  <div
                    v-if="this.googleAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      :to="`/client/${client.slug}`"
                      >Select an account</b-button
                    >
                  </div>
                  <div
                    v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;"
                  >
                    <div
                      style="display: flex;  justify-content: space-evenly; "
                    >
                      <div style=" display: flex; ">
                        <div
                          style=" display: flex; justify-content: space-between;"
                        >
                          <p
                            style="font-size:11px; color: #7a7a7a; width:100px"
                          >
                            <b>Spends</b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ; "
                            >
                              {{ this.googleSpendsToday }}
                            </span>
                            <br />
                            <span
                              v-if="this.googleSpendsDelta > 0"
                              class="text-success"
                              v-b-tooltip.hover.bottom
                              :title="
                                'Yesterday : ' + this.googleSpendsYeserday
                              "
                            >
                              <feather-icon
                                icon="ArrowUpIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon
                              >{{ Math.abs(this.googleSpendsDelta) }}%
                            </span>
                            <span
                              v-else
                              class="text-danger"
                              v-b-tooltip.hover.bottom
                              :title="
                                'Yesterday : ' + this.googleSpendsYeserday
                              "
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon
                              >{{ Math.abs(this.googleSpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div
                          style="display: flex; justify-content: space-between;"
                        >
                          <p
                            style="font-size:11px; color: #7a7a7a;width:100px; "
                          >
                            <b
                              ><span v-if="client.businessType[0] == 'leadgen'"
                                >Leads</span
                              >
                              <span
                                v-else-if="
                                  client.businessType[0] == 'ecommerce'
                                "
                                >Purchases</span
                              >
                              <span v-else>Conversions</span></b
                            ><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;"
                            >
                              <span
                                v-if="client.businessType[0] == 'ecommerce'"
                              >
                                {{ this.googleWebPurchasesToday }}</span
                              >
                              <span v-else>{{
                                this.googleConversionToday
                              }}</span>
                            </span>
                            <br />
                            <span v-if="client.businessType[0] == 'ecommerce'">
                              <span
                                v-if="this.googleWebPurchasesDelta > 0"
                                v-b-tooltip.hover.bottom
                                class="text-success"
                                :title="
                                  'Yesterday : ' +
                                    this.googleWebPurchasesYeserday
                                "
                              >
                                <feather-icon
                                  icon="ArrowUpIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon
                                >{{ Math.abs(this.googleWebPurchasesDelta) }}%
                              </span>
                              <span
                                v-else
                                class="text-danger"
                                v-b-tooltip.hover.bottom
                                :title="
                                  'Yesterday : ' +
                                    this.googleWebPurchasesYeserday
                                "
                              >
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon
                                >{{ Math.abs(this.googleWebPurchasesDelta) }}%
                              </span>
                            </span>
                            <span v-else>
                              <span
                                v-if="this.googleConversionDelta > 0"
                                v-b-tooltip.hover.bottom
                                class="text-success"
                                :title="
                                  'Yesterday : ' + this.googleConversionYeserday
                                "
                              >
                                <feather-icon
                                  icon="ArrowUpIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon
                                >{{ Math.abs(this.googleConversionDelta) }}%
                              </span>
                              <span
                                v-else
                                class="text-danger"
                                v-b-tooltip.hover.bottom
                                :title="
                                  'Yesterday : ' + this.googleConversionYeserday
                                "
                              >
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon
                                >{{ Math.abs(this.googleConversionDelta) }}%
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCols" v-if="this.amazonAdsConnected">
            <b-link :to="`/client/${client.slug}/dashboard/amazon-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer " v-else>
                  <span class="todayText">
                    <b-icon
                      icon="circle-fill"
                      font-scale="1"
                      color="#41B06E"
                      class="dotLive"
                    /><span class="blink_text">Today</span></span
                  >
                  <div
                    class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;"
                  >
                    <b-link :to="`/client/${client.slug}/dashboard/amazon-ads`">
                      <b-avatar
                        square
                        variant="white"
                        size="45"
                        src="../../assets/images/logo/amazon.png"
                      >
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-1">
                      Amazon
                    </b-card-text>
                  </div>
                  <div
                    v-if="this.amazonAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      :to="`/client/${client.slug}`"
                      >Select an account</b-button
                    >
                  </div>
                  <div
                    v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;"
                  >
                    <div
                      style="display: flex;  justify-content: space-evenly; "
                    >
                      <div style=" display: flex; ">
                        <div
                          style=" display: flex; justify-content: space-between;"
                        >
                          <p
                            style="font-size:11px; color: #7a7a7a; width:100px"
                          >
                            <b>Spends</b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;"
                            >
                              {{ this.amazonSpendsToday }}
                            </span>
                            <br />
                            <span
                              v-if="this.amazonSpendsDelta > 0"
                              class="text-success"
                              v-b-tooltip.hover.bottom
                              :title="
                                'Yesterday : ' + this.amazonSpendsYeserday
                              "
                            >
                              <feather-icon
                                icon="ArrowUpIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon
                              >{{ Math.abs(this.amazonSpendsDelta) }}%
                            </span>
                            <span
                              v-else
                              class="text-danger"
                              v-b-tooltip.hover.bottom
                              :title="
                                'Yesterday : ' + this.amazonSpendsYeserday
                              "
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon>
                              {{ Math.abs(this.amazonSpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div
                          style="display: flex; justify-content: space-between;"
                        >
                          <p
                            style="font-size:11px; color: #7a7a7a; width:100px"
                          >
                            <b>Conversions</b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;"
                            >
                              {{ this.amazonConversionToday }}
                            </span>
                            <br />

                            <span
                              v-if="this.amazonConversionDelta > 0"
                              class="text-success"
                              v-b-tooltip.hover.bottom
                              :title="
                                'Yesterday : ' + this.amazonConversionYeserday
                              "
                            >
                              <feather-icon
                                icon="ArrowUpIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon
                              >{{ Math.abs(this.amazonConversionDelta) }}%
                            </span>
                            <span
                              v-else
                              class="text-danger"
                              v-b-tooltip.hover.bottom
                              :title="
                                'Yesterday : ' + this.amazonConversionYeserday
                              "
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon
                              >{{ Math.abs(this.amazonConversionDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCols" v-if="this.facebookAdsConnected">
            <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer" v-else>
                  <span class="todayText">
                    <b-icon
                      icon="circle-fill"
                      font-scale="1"
                      color="#41B06E"
                      class="dotLive"
                    /><span class="blink_text">Today</span></span
                  >
                  <div
                    class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;"
                  >
                    <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
                      <b-avatar
                        square
                        variant="white"
                        size="45"
                        src="../../assets/images/logo/meta.png"
                      >
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-1">
                      Meta
                    </b-card-text>
                  </div>
                  <div
                    v-if="this.metaAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;"
                  >
                    <b-button
                      variant="primary"
                      size="sm"
                      :to="`/client/${client.slug}`"
                      >Select an account</b-button
                    >
                  </div>
                  <div
                    v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;"
                  >
                    <div
                      style="display: flex;  justify-content: space-evenly; "
                    >
                      <div style=" display: flex; ">
                        <div
                          style=" display: flex; justify-content: space-between; "
                        >
                          <p
                            style="font-size:11px; color: #7a7a7a; width:100px"
                          >
                            <b>Spends</b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;"
                            >
                              {{ this.metaSpendsToday }}
                            </span>
                            <br />
                            <span
                              v-if="this.metaSpendsDelta > 0"
                              v-b-tooltip.hover.bottom
                              class="text-success"
                              :title="'Yesterday : ' + this.metaSpendsYeserday"
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon>
                              {{ Math.abs(this.metaSpendsDelta) }}%
                            </span>
                            <span
                              v-else
                              class="text-danger"
                              :title="'Yesterday : ' + this.metaSpendsYeserday"
                              v-b-tooltip.hover.bottom
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                              ></feather-icon>
                              {{ Math.abs(this.metaSpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div
                          style="display: flex; justify-content: space-between; "
                        >
                          <p
                            style="font-size:11px; color: #7a7a7a; width:100px"
                          >
                            <b>
                              <span v-if="client.businessType[0] == 'leadgen'"
                                >Leads</span
                              >
                              <span
                                v-else-if="
                                  client.businessType[0] == 'ecommerce'
                                "
                                >Purchases</span
                              >
                              <span v-else>Conversions</span></b
                            ><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;"
                            >
                              <span
                                v-if="client.businessType[0] == 'ecommerce'"
                              >
                                {{ this.metaWebPurchasesToday }}</span
                              >
                              <span v-else>{{ this.metaConversionToday }}</span>
                            </span>
                            <br />
                            <span v-if="client.businessType[0] == 'ecommerce'">
                              <span
                                v-if="this.metaWebPurchasesDelta > 0"
                                class="text-success"
                                v-b-tooltip.hover.bottom
                                :title="
                                  'Yesterday : ' + this.metaWebPurchasesYeserday
                                "
                              >
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon>
                                {{ Math.abs(this.metaWebPurchasesDelta) }}%
                              </span>
                              <span
                                v-else
                                class="text-danger"
                                :title="
                                  'Yesterday : ' + this.metaWebPurchasesYeserday
                                "
                                v-b-tooltip.hover.bottom
                              >
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon>
                                {{ Math.abs(this.metaWebPurchasesDelta) }}%
                              </span>
                            </span>
                            <span v-else>
                              <span
                                v-if="this.metaConversionDelta > 0"
                                class="text-success"
                                v-b-tooltip.hover.bottom
                                :title="
                                  'Yesterday : ' + this.metaConversionYeserday
                                "
                              >
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon>
                                {{ Math.abs(this.metaConversionDelta) }}%
                              </span>
                              <span
                                v-else
                                class="text-danger"
                                :title="
                                  'Yesterday : ' + this.metaConversionYeserday
                                "
                                v-b-tooltip.hover.bottom
                              >
                                <feather-icon
                                  icon="ArrowDownIcon"
                                  size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"
                                ></feather-icon>
                                {{ Math.abs(this.metaConversionDelta) }}%
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCardCols">
            <b-card class="cardContainer">
              <div
                v-if="this.showSummaryDashboardLoadingGraph"
                class="spinnerCentered"
              >
                <multiChannelSpinner />
              </div>
              <div v-else>
                <h3 style="text-align: center; color: grey; font-size: 11px;">
                  Spends
                </h3>
                <apexchart
                  type="line"
                  height="63"
                  :options="chartOptionsBench"
                  :series="seriesCostBenchMark"
                >
                </apexchart>
                <div
                  style="display: flex; justify-content: space-between; font-size: 9px;"
                >
                  <span>{{ this.dateRange.startDate | date }}</span>
                  <span> {{ this.dateRange.endDate | date }}</span>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col :cols="getCardCols">
            <b-card class="cardContainer">
              <div
                v-if="this.showSummaryDashboardLoadingGraph"
                class="spinnerCentered"
              >
                <multiChannelSpinner />
              </div>
              <div v-else>
                <h3 style="text-align: center; color: grey;font-size: 11px;">
                  <span v-if="client.businessType[0] == 'leadgen'">Leads</span>
                  <span v-else-if="client.businessType[0] == 'ecommerce'"
                    >Purchases</span
                  >
                  <span v-else>Conversions</span>
                </h3>
                <apexchart
                  type="line"
                  height="63"
                  :options="chartOptionsBench"
                  :series="seriesConversionsBenchMark"
                >
                </apexchart>
                <div
                  style="display: flex; justify-content: space-between; font-size: 9px;"
                >
                  <span>{{ this.dateRange.startDate | date }}</span>
                  <span> {{ this.dateRange.endDate | date }}</span>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div v-if="this.tabMultiChannelName === 'ProjectInsights'">
        <div
          v-if="
            client &&
              client.accessConfiguration &&
              client.accessConfiguration.projects.enabled
          "
        >
          <b-row v-if="!hideOverallProjectsTab">
            <b-col cols="12">
              <b-card>
                <div v-if="this.isProjectWiseIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div v-else>
                  <MultiHeaderProjectChannel
                    :projectName="
                      'Project(s) Summary ' +
                        this.formatDateDisplay(this.dateRange.startDate) +
                        ' to ' +
                        this.formatDateDisplay(this.dateRange.endDate)
                    "
                    v-if="
                      client &&
                        client.accessConfiguration &&
                        client.accessConfiguration.projects.enabled
                    "
                    type="overallChannelProjectData"
                    :businessTypeProject="client.businessType[0]"
                    :tableData="projectWiseData"
                    :clientName="client.name"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row v-if="this.tabMultiChannelName === 'ChannelPerformanceOverview'">
        <b-col cols="12">
          <div>
            <b-card>
              <div v-if="projectOverViewLoad" class="spinnerCentered">
                <multiChannelSpinner />
              </div>
              <div v-else>
                <div style="display: flex; justify-content: space-between;">
                  <b-card-title
                    style="font-size: 14px; font-weight: bold; color: #200E6B;"
                  >
                    Campaign Performance Overview
                  </b-card-title>
                  <div
                    style="display: flex; justify-content: space-between; gap: 10px;"
                  >
                    <div
                      style="display: flex; justify-content: space-between; gap: 10px;"
                    >
                      <div style="margin-top: -10px;">
                        <label>Campaign:</label>
                        <b-form-input
                          id="boomark-search-input"
                          v-model="searchByCampaignName"
                          placeholder="Search By Campaign"
                        />
                      </div>
                      <!-- <v-select style="min-width: 450px;max-width: 550px;" label="name" placeholder="Select Campagin"
                    v-model="selectedFilterCampaign" multiple :options="optionsCampaignNamses" /> -->
                    </div>
                    <div
                      style="display: flex; justify-content: space-between; gap: 10px;"
                    >
                      <div style="margin-top: -10px;">
                        <label>Data Channel:</label>
                        <b-form-select
                          v-model="selectedAdsOption"
                          :options="optionsAds"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="projectOverviewTable">
                  <table>
                    <thead>
                      <tr>
                        <th :style="{ backgroundColor: firstColumnColor }">
                          {{ getChannelOverviewData[0].title }}
                        </th>
                        <th
                          v-for="(column,
                          index) in getChannelOverviewData.slice(1)"
                          :key="index"
                          :style="{
                            backgroundColor: column ? column.color : '',
                          }"
                        >
                          {{ getChannelOverviewData[index + 1].title }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, rowIndex) in this.displayedOverviewItems"
                        :key="rowIndex"
                      >
                        <td
                          :style="
                            getCellStyle(
                              getChannelOverviewData[0].color,
                              row.campaign
                            )
                          "
                          style="font-weight: bold;"
                        >
                          {{ row.campaign }}
                        </td>
                        <td
                          style="font-weight:bold"
                          :style="
                            getCellStyle(
                              getChannelOverviewData[1].color,
                              row.source
                            )
                          "
                        >
                          {{ row.source }}
                        </td>
                        <td
                          style="font-weight:bold"
                          :style="
                            getCellStyle(
                              getChannelOverviewData[2].color,
                              row.metricsUnformatted.clicks
                            )
                          "
                        >
                          {{ row.metrics.clicks }}
                        </td>
                        <td
                          style="font-weight:bold"
                          :style="
                            getCellStyle(
                              getChannelOverviewData[3].color,
                              row.metricsUnformatted.impressions
                            )
                          "
                        >
                          {{ row.metrics.impressions }}
                        </td>
                        <!-- <td
                        :style="
                          getCellStyle(
                            columns[4].color,
                            row.metrics.impressions
                          )
                        "
                      >
                        {{ row.metrics.impressions }}
                      </td> -->
                        <td
                          style="font-weight:bold"
                          :style="
                            getCellStyle(
                              getChannelOverviewData[4].color,
                              row.metricsUnformatted.spends
                            )
                          "
                        >
                          {{ row.metrics.spends }}
                        </td>
                        <td
                          style="font-weight:bold"
                          :style="
                            getCellStyle(
                              getChannelOverviewData[5].color,
                              row.metricsUnformatted.conversions
                            )
                          "
                        >
                          {{ row.metrics.conversions }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="justify-content: center; margin-top: 10px; ">
                    <b-pagination
                      v-if="this.fetchOverViewAllData.length > 0"
                      v-model="currentPage"
                      :total-rows="filterDataLength"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                    ></b-pagination>
                  </div>
                  <div v-if="fetchOverViewAllData.length === 0">
                    <div style="padding: 70px 0; text-align: center;">
                      <h4 class="text-primary">No Data Found.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'ProductOutCome'">
        <b-col cols="12">
          <b-card
            class="multiHeaderMulti"
            style="margin-left:2px; margin-right: 2px;"
          >
            <ProjectOutXL
              @getXLMonthOnChange="getXLMonthDataChange"
              :isXLDataFetching="isXLDataFetched"
              :maxXLCol="projectOutComeCol"
              :maxXLRow="projectOutComeRow"
              :tableDataXL="projectOutComeXL"
              :clientId="client._id"
              @getXLReport="fetchProjectOutComeXL"
            />
            <!-- <ProductOutCome :clientID="client._id" :clientName="client.name" :productOutComeData="productOutWiseData" :dataLoadFlag="fetchProductOutWiseData" @callOutComeGetApi="handleCallOutComeGetApiChange"/> -->
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'AffiliateInsights'">
        <b-col cols="12">
          <b-card
            class="multiHeaderMulti"
            style="margin-left:2px; margin-right: 2px;"
          >
            <AffilateInsights
              :clientID="client._id"
              @filterAffliatesType="handledAffilatesType"
              @filterAffliatesChannelType="handledAffilatesChannelType"
              :clientName="client.name"
              :AffilatedData="affilatesOutWiseData"
              :dataLoadAffilatedFlag="fetchAffilatestOutWiseData"
              :affilatesOptions="affilatesAllOptions"
              @callAffilatesGetApi="handleAffilatesGetApiChange"
              :intialAffilatesWiseOptions="affilatesWiseOptionsIntial"
              :affilatesWiseOptions="affilatesWiseOptions"
              @callAfflilatesComeGetApi="handleCallAffilatesGetApiChange"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'TargetVSAchieved'">
        <b-col cols="12">
          <b-card>
            <TargetVsAchieved
              :clientId="client._id"
              :projectNames="zoneWiseTypeOptions"
              :getTargetVsAchivedData="targetVsAchivedData"
              :isTargetVsAchievedLoad="isTargetValueFetched"
              @getChannelType="getChangedChannelTypeTarget"
              @getTargetTypeChange="getChangedTarget"
              @getTargetProjectNameType="getChangedProjectNameTargetCall"
              @targetCalCulated="fetchTargetVsAchived"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'ZoneInsights'">
        <b-col cols="12">
          <b-card
            class="multiHeaderMulti"
            style="margin-left:2px; margin-right: 2px;"
          >
            <div v-if="this.optionsLength === 0" class="spinnerCentered">
              <p class="p-4">No Data found!</p>
              <!-- <multiChannelSpinner /> -->
            </div>
            <div v-else>
              <MultiHeader
                :clientId="client._id"
                :clientName="client.name"
                :reportType="reportModeSelected"
                :zoneWiseType="zoneWiseTypeOptions"
                :tableData="zoneWiseData"
                type="zoneWise"
                selectionType="single"
                :businessTypeProject="client.businessType[0]"
                channelType="overall"
                :projectNameSummary="'Group(Zone) Insights'"
                selectBoxType="Select Zone(s)"
                @sourceChangedData="getSourceData"
                :zoneWiseDataLoading="isZonewiseDatafetched"
                :rangeType="reportModeSelected"
                @getSubProductOptions="getSubOptions"
                @zoneTypeChangedData="getZoneTypeData"
                @handleZoneListChange="handleZoneList"
                @filterDataZoneWiseType="handledZoneType"
                :zoneWiseOptionsLoad="this.optionsLength"
                @openModal="openDataToZonewiseModal"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'ProductInsights'">
        <b-col cols="12">
          <b-card
            class="multiHeaderMulti"
            style="margin-left:2px; margin-right: 2px;"
          >
            <MultiHeader
              :clientName="client.name"
              :productAllProjectWiseType="productWiseTypeOptions"
              :tableData="productWiseData"
              type="productWise"
              selectionType="multiple"
              @sourceProductChangedData="getProductSourceData"
              :businessTypeProject="client.businessType[0]"
              :projectNameSummary="'Product Insights'"
              channelType="overall"
              selectBoxType="Select Product(s)"
              :productWiseDataLoading="isProductDatafetched"
              @handleZoneListProjectChange="handleProductProjectList"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'Trends'">
        <b-col cols="12">
          <TrendsTab
            @sourceTrendsChangedData="getTrendsSourceData"
            :trendOptionsType="zoneWiseTypeOptions"
            :trendsOverallData="TrendsData"
            :tableTrendsData="TrendsTableDataFetched"
            :ifFetchedTrends="isTrendsDataFetched"
            :ifClientFetchedTrends="idClientEntireLoad"
            :ifFetchedTableDataTrends="isTrendsTableDataFetched"
            :isQualityVolumeChartDataFetched="isQualityVolumeFetched"
            :qualityVolumeChartData="isQualityVolumeData"
            :isLeadsDataFetched="isLeadsFetched"
            :leadsData="LeadsData"
            :selectedDates="
              this.formatDateDisplay(this.dateRange.startDate) +
                ' to ' +
                this.formatDateDisplay(this.dateRange.endDate)
            "
          />
        </b-col>
      </b-row>
      <b-row v-if="this.tabMultiChannelName === 'CreativeAnalysis'">
        <b-col cols="12">
          <CreativeAnalysis
            :trendsOverallData="TrendsData"
            :isLoadCreatives="zoneWiseTypeOptionsFlag"
            :clientId="client._id"
            :optionsListFlag="zoneWiseTypeOptionsFlag"
            :optionsList="creativeOptions"
            :creativesData="isCreativesFlag"
            :graphData="creativesData"
            @changedCreativesProjectName="changeCreativesProjectName"
            @changedCreativeMetricChange="changeCreativeMetricChange"
            @changeChannelChange="changedChannelChange"
          />
        </b-col>
      </b-row>
    </div>
    <!-- Spends Tracker modal -->
    <b-modal
      v-model="addSpendsModal"
      id="showShopNameModal"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-card-text>
        <div class="header text-right">
          <feather-icon
            style="cursor: pointer"
            @click="closeAddSpendsModal"
            class="Xicon"
            icon="XIcon"
          ></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <h6 class="app-title lg">
              Add budget to track spends
            </h6>
          </div>
        </div>
      </b-card-text>
      <div class="row p-1 addSpendsFormContainer">
        <div class="col-12 col-md-4 mb-1 formdiv">
          <b-form-group label="*Enter amount" label-for="project-name">
            <b-form-input
              id="project-name"
              type="number"
              v-model="spendsAmount"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3 mb-1 formdiv">
          <b-form-group label="*Received Date" label-for="received-date">
            <b-form-datepicker
              id="received-date"
              class="date-range-formatting"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              locale="en"
              v-model="receivedDate"
              size="sm"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3 mb-1 formdiv">
          <b-form-group
            label="*Start Tracking From"
            label-for="start-tracking-date"
          >
            <b-form-datepicker
              id="start-tracking-date"
              v-model="startTrackingDate"
              size="sm"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              locale="en"
              :disabled="isTrackingDateDisabled"
              :max="maxDate"
            ></b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-12 col-md-2 mb-1 formdiv">
          <b-button
            variant="primary"
            class="mt-2 w-100 saveButton"
            @click="addTransaction"
            :disabled="isCreateButtonDisabled"
          >
            <span>Save <b-spinner v-if="addingSpends" small></b-spinner></span>
          </b-button>
        </div>
      </div>
      <div style="display: flex; justify-content: center;padding: 2px;"></div>
      <div
        v-if="this.addingSpends || this.spendsTrasactionFetch"
        class="spinnerCentered"
      >
        <span>
          <multiChannelSpinner />
        </span>
      </div>
      <div
        v-else-if="
          Object.keys(this.spendsTrasactionData).length === 0 &&
            this.filteredDataBasedOnAct
        "
        class="spinnerCentered"
      >
        <span class="mb-2">Add a transaction to start spends tracking!</span>
      </div>
      <div
        v-else-if="
          Object.keys(this.spendsTrasactionData).length > 0 &&
            this.filteredDataBasedOnAct
        "
        class="spinnerCentered"
      >
        <span>No transactions found!</span>
      </div>
      <div v-else>
        <div
          v-if="
            this.spendsTrasactionData && this.spendsTrasactionData.length > 0
          "
        >
          <div
            style="display: flex; justify-content: space-between; font-size: 13px;"
          >
            <p><strong>Transaction History</strong></p>
            <p>
              <strong>Tracking start date:</strong>
              {{
                new Date(this.transactionStarts).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })
              }},
              <span v-if="this.spendsAmountOnTop > 0"
                ><strong>Total Amount</strong>:{{ this.spendsCurrencyOnTop }}
                {{ this.spendsAmountOnTop }}</span
              >
            </p>
          </div>
          <div class="transaction-table">
            <b-table
              striped
              hover
              :items="filteredDataBasedOnIsActive"
              :fields="fields"
              class="custom-spends-table"
            >
              <template v-slot:cell(actions)="row">
                <div class="action-buttons">
                  <span @click="openEditSpendsModal(row)" class="action-icon"
                    ><feather-icon icon="EditIcon" size="18"> </feather-icon>
                  </span>
                  <span @click="openDeleteSpendsModal(row)" class="action-icon"
                    ><feather-icon color="red" icon="TrashIcon" size="18"
                  /></span>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Edit Spends Tracker modal -->
    <b-modal
      v-model="editSpendsModal"
      id="showShopNameModal"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <b-card-text>
        <div class="header text-right">
          <feather-icon
            style="cursor: pointer"
            @click="closeEditSpendsModal"
            class="Xicon"
            icon="XIcon"
          ></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <h6 class="app-title lg">
              Edit budget to track spends
            </h6>
          </div>
        </div>
      </b-card-text>
      <b-row style="padding:6px;">
        <b-col cols="12">
          <b-form-group label="*Enter amount" label-for="project-name">
            <b-form-input
              id="project-name"
              v-model="editSpendsAmount"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="*Received Date" label-for="rules-select">
            <!-- <b-form-datepicker
              id="rules-select"
              v-model="receivedDate"
              required
            ></b-form-datepicker> -->
            <b-form-datepicker
              class="date-range-formatting"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              locale="en"
              v-model="receivedDate"
              id="datepicker-sm"
              size="sm"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12" v-if="isEditSpends">
          <b-form-group label="*Start Tracking From" label-for="tag-input">
            <b-form-datepicker  v-model="startTrackingDate" id="datepicker-sm" size="sm" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
      locale="en"></b-form-datepicker>
          </b-form-group> -->
        <!-- </b-col> -->
        <b-col cols="12">
          <b-button
            style="float: right;"
            variant="primary"
            class="mt-2"
            @click="updateSpendsTransaction"
            :disabled="isEditButtonDisabled"
          >
            <b-col class="d-flex justify-content-center align-items-center">
              <span
                >Update <b-spinner v-if="isEditSpends" small></b-spinner
              ></span>
              <!-- <b-spinner v-if="isCreatedProject" small></b-spinner> -->
            </b-col>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="spendsDeleteModal"
      id="showShopNameModal"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div class="header text-right">
        <feather-icon
          style="cursor: pointer"
          @click="closeDeleteSpendsModal"
          class="Xicon"
          icon="XIcon"
        ></feather-icon>
      </div>
      <div class="d-block text-center">
        <h6>Are you sure you want to delete the transaction entry?</h6>
      </div>
      <div class="popup-modal ml-2">
        <b-button
          class="mt-2"
          @click="closeDeleteSpendsModal"
          style="min-width: 150px"
          >Cancel</b-button
        >
        <b-button
          style="min-width: 150px"
          class="ml-1 mt-2"
          variant="danger"
          @click="deleteASpends()"
        >
          Delete <b-spinner v-if="isDeleteSpends" small></b-spinner>
        </b-button>
      </div>
      <div></div>
    </b-modal>
    <b-modal
      v-model="addDataToZonewiseModal"
      id="showShopNameModal"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-card-text>
        <div style="display: flex; justify-content:space-between; padding:10px">
          <h5 class="app-title mdsm">
            Get Target VS Achieved Value:
          </h5>
          <div class="header text-right">
            <feather-icon
              style="cursor: pointer"
              @click="CloseDataToZonewiseModal"
              class="Xicon"
              icon="XIcon"
            ></feather-icon>
          </div>
        </div>
      </b-card-text>
      <div
        v-if="isFetchTargetValue"
        class="spinnerCentered"
        style="margin-top: 15%;"
      >
        <multiChannelSpinner />
      </div>
      <div v-else style="padding: 5px 30px">
        <b-row>
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Targeted Value:</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-1"
              label="Spends:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                type="number"
                v-model="targetSpends"
                placeholder="Enter Spends"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                v-model="targetLeads"
                placeholder="Enter Leads"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                placeholder="Enter CPL"
                v-model="targetCpl"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Achieved Value:</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-1"
              label="Spends:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                type="number"
                v-model="achievedSpends"
                placeholder="Enter Spends"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                v-model="achievedLeads"
                placeholder="Enter Leads"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                v-model="achievedCpl"
                placeholder="Enter CPL"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row style="margin-right: -5px; margin-top:5px">
          <div style="display: flex; width: 100%;">
            <div style="flex-grow: 1;"></div>
            <b-button
              variant="primary"
              style="margin-left: auto;"
              :disabled="
                this.targetCpl === 0 ||
                  this.targetLeads === 0 ||
                  this.targetLeads === 0
              "
              @click="calculateTargetVsAchieved"
            >
              Calculate Difference
              <b-spinner v-if="isCalculating" small></b-spinner>
            </b-button>
          </div>
        </b-row>
        <hr v-if="isCalulatedSuccessFully" />
        <b-row v-if="isCalulatedSuccessFully">
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Difference :</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-1"
              label="Spends:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                type="number"
                v-model="differenceSpends"
                placeholder="Enter Spends"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                v-model="differenceLeads"
                placeholder="Enter Leads"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                v-model="differenceCpl"
                placeholder="Enter CPL"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCalulatedSuccessFully">
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Difference % :</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group
              id="input-group-1"
              label="Spends:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                type="number"
                v-model="differencePerSpends"
                placeholder="Enter Spends"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="number"
                placeholder="Enter Leads"
                v-model="differencePerLeads"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input
                id="input-1"
                type="email"
                placeholder="Enter CPL"
                v-model="differencePerCpl"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr />
      <b-row class="mt-2 p-1">
        <div style="display: flex; width: 100%;">
          <div style="flex-grow: 1;"></div>
          <b-button
            variant="primary"
            style="margin-left: auto;"
            @click="CloseDataToZonewiseModal"
          >
            Done
          </b-button>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCarousel,
  BCarouselSlide,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BFormSelect,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCardTitle,
  BPagination,
  BLink,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BIcon,
  BSpinner,
  BFormGroup,
  BFormInput,
  BTable,
  BFormDatepicker,
  BBadge,
  BTabs,
  BTab,
  BFormRadio,
} from "bootstrap-vue";
import ProductOutCome from "./ProductOutCome.vue";
import AffilateInsights from "./AffilateInsights.vue";
import TrendsTab from "./TrendsTab.vue";
import CreativeAnalysis from "./CreativeAnalysis.vue";
import MultiHeader from "./MultiHeader.vue";
import Spinner from "@/components/client/Spinner.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import ProjectOutXL from "../clientFeature/ProjectOutXL.vue";
import VueApexCharts from "vue-apexcharts";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
const $trackBgColor = "#EBEBEB";
import millify from "millify";
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import MultiHeaderProjectChannel from "../clientFeature/MultiheaderProjectChannel.vue";
import client from "@/router/client";
import { data } from "vue2-dropzone";
import TargetVsAchieved from "./TargetVsAchieved.vue";
export default {
  data() {
    return {
      selectedXLMonth: new Date().getMonth(),
      selectedXLYear: "2024",
      projectOutComeXL: {},
      projectOutComeCol: 0,
      projectOutComeRow: 0,
      isXLDataFetched: false,
      targetType: "weekly",
      channelType: "all",
      getChangedProjectNameTarget: "all",
      targetVsAchivedData: [],
      isTargetValueFetched: false,
      isCalulatedSuccessFully: false,
      isCalculating: false,
      selectedTargetVsAchivedVal: "",
      selectedZoneDate: "",
      achievedCpl: 0,
      achievedSpends: 0,
      achievedLeads: 0,
      targetCpl: 0,
      targetSpends: 0,
      targetLeads: 0,
      differenceCpl: 0,
      differenceSpends: 0,
      differenceLeads: 0,
      differencePerCpl: 0,
      differencePerSpends: 0,
      differencePerLeads: 0,
      isFetchTargetValue: false,
      isCalculatedTargetVsAchieved: false,
      addDataToZonewiseModal: false,
      productOutWiseData: [],
      affilatesWiseOptions: [],
      affilatesWiseOptionsIntial: "",
      affilatesAllOptions: [],
      fetchProductOutWiseData: false,
      fetchAffilatestOutWiseData: false,
      affilatesOutWiseData: [],
      selectedProductSourceType: "all",
      reportModeSelected: "daily",
      productProjectNamesChange: [],
      productProjectIntialNamesChange: [],
      productWiseTypeOptions: [],
      intiallOptionsForGroup: [],
      initiallProjectName: "",
      CreativesProjectName: "",
      selectedCreativesMetricType: "",
      selectedCreativesChannelType: "",
      creativeOptions: [],
      isCreativesFlag: false,
      creativesData: [],
      TrendsData: [],
      TrendsTableDataFetched: [],
      isTrendsDataFetched: false,
      isTrendsTableDataFetched: false,
      isQualityVolumeFetched: false,
      isLeadsFetched: false,
      LeadsData: [],
      isQualityVolumeData: [],
      optionsLength: 0,
      subProductOptions: "all",
      zoneListChange: [],
      selectedProductType: "all",
      zoneWiseTypeOptions: null,
      zoneWiseTypeOptionsFlag: false,
      searchByCampaignName: "",
      // selectedFilterCampaign:[],
      isProductDatafetched: false,
      productWiseData: [],
      zoneWiseData: [],
      tabMultiChannelName: "",
      conditionalSpends: "",
      spendsMessage: "",
      spendsTracker: {},
      isSpendsLoading: false,
      conditionalSpends: "",
      spendsMessage: "",
      spendsTracker: {},
      isZonewiseDatafetched: false,
      isEditSpends: false,
      editSpendsAmount: 0,
      spendsAmountOnTop: 0,
      spendsCreatedAtOnTop: "",
      spendsCurrencyOnTop: "",
      isDeleteSpends: false,
      selectedTrasactionId: "",
      addingSpends: false,
      transactionStarts: "",
      spendsTrasactionFetch: false,
      spendsTrasactionData: [],
      spendsDeleteModal: false,
      editSpendsModal: false,
      spendsAmount: 0,
      receivedDate: new Date().toISOString().substr(0, 10),
      startTrackingDate: new Date().toISOString().substr(0, 10),
      users: [
        { id: 1, name: 202, email: 4232 },
        { id: 2, name: 202, email: 343242 },
        { id: 3, name: 234242, email: 34234 },
        { id: 4, name: 4313424, email: 34234 },
      ],
      fields: [
        { key: "amount", label: "Amount" },
        {
          key: "receivedAt",
          label: "Received At",
          formatter: this.formatDateTime,
        },
        // { key: 'extraField', label: 'Tracking Starts At',formatter: this.formatDateTime },
        {
          key: "updatedAt",
          label: "Entry Created At",
          formatter: this.formatDateTime,
        },
        { key: "actions", label: "Actions" }, // Custom column for actions
      ],
      addSpendsModal: false,
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      selectedReportID: "",
      reportType: ["Daily", "Weekly"],
      selectedReportType: "Daily",
      selectedReportMonth: new Date().getMonth(),
      months: [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ],
      selectedYear: new Date().getFullYear(),
      year: [new Date().getFullYear()],
      idClientEntireLoad: false,
      projectOverViewLoad: false,
      hideOverallProjectsTab: false,
      isProjectWiseIsLoad: false,
      projectWiseData: {},
      filterDataLength: 0,
      googleAdsId: "",
      metaAdsId: "",
      amazonAdsId: "",
      shopifyAdsConnected: false,
      googleAdsConnected: false,
      amazonAdsConnected: false,
      facebookAdsConnected: false,
      gmbConnected: false,
      analyticsConnected: false,
      isRevenueEmpty: true,
      isLiveDataIsLoad: false,
      amazonConversionToday: 0,
      amazonConversionYeserday: 0,
      amazonConversionDelta: 0,
      amazonSpendsToday: 0,
      amazonSpendsYeserday: 0,
      amazonSpendsDelta: 0,
      amazonWebPurchasesToday: 0,
      amazonWebPurchasesYeserday: 0,
      amazonWebPurchasesDelta: 0,
      metaConversionToday: "",
      metaConversionYeserday: 0,
      metaConversionDelta: 0,
      metaSpendsToday: 0,
      metaSpendsYeserday: 0,
      metaSpendsDelta: 0,
      metaWebPurchasesToday: 0,
      metaWebPurchasesYeserday: 0,
      metaWebPurchasesDelta: 0,
      googleConversionToday: 0,
      googleConversionYeserday: 0,
      googleConversionDelta: 0,
      googleSpendsToday: 0,
      googleSpendsYeserday: 0,
      googleSpendsDelta: 0,
      googleWebPurchasesToday: "",
      googleWebPurchasesYeserday: "",
      googleWebPurchasesDelta: "",
      client: [],
      costArray: [],
      datesArray: [],
      conversionsArray: [],
      webPurchasesTotal: 0,
      googleConversion: 0,
      googleSpends: 0,
      googleImpressions: 0,
      googleClicks: 0,
      googleRevenue: 0,
      googleWebPurchases: 0,
      impressionsTotal: 0,
      metaConversion: 0,
      metaWebPurchases: 0,
      metaSpends: 0,
      metaRevenue: 0,
      metaImpressions: 0,
      metaClicks: 0,
      spendsTotal: 0,
      amazonConversion: 0,
      amazonSpends: 0,
      amazonImpressions: 0,
      amazonClicks: 0,
      amazonRevenue: 0,
      revenueTotal: 0,
      cplTotal: 0,
      metaCPL: 0,
      amazonCPL: 0,
      googleCPL: 0,
      ctrTotal: 0,
      metaCTR: 0,
      amazonCRT: 0,
      googleCRT: 0,
      clicksTotal: 0,
      conversionsTotal: 0,
      fetchBenchMarkDataValue: [],
      fetchBenchMarkAllDataValue: [],
      fetchRealTime: [],
      startDateForAll: "",
      endDateForAll: "",
      seriesCostBenchMark: [
        {
          name: "Spends",
          data: [],
        },
      ],
      seriesConversionsBenchMark: [
        {
          name: "Leads",
          data: [],
        },
      ],
      chartOptionsBench: {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: $trackBgColor,
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -30,
            bottom: -12,
          },
        },
        stroke: {
          width: 3,
        },
        colors: ["#7ab240"],
        markers: {
          size: 2,
          colors: "#7ab240",
          strokeColors: "#7ab240",
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              // dataPointIndex: this.dataPointIndex(),
              fillColor: "#ffffff",
              strokeColor: "#7ab240",
              size: 5,
            },
          ],
          shape: "circle",
          radius: 2,
          hover: {
            size: 3,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: false,
        },
      },
      isCalculatedTargetVsAchieved: false,
      showSummaryDashboardLoading: false,
      showSummaryDashboardLoadingGraph: false,
      fetchOverViewAllData: [],
      currentPage: 1,
      selectedSourceType: "all",
      selectedTrendsSourceType: "all",
      perPage: 10,
      // seriesClicks: [this.metaClicks, this.amazonClicks, this.googleClicks],
      seriesClicks: [],
      seriesSpends: [],
      seriesImpressions: [],
      seriesConversions: [],
      seriesRevenue: [],
      seriesCPL: [],
      seriesCTR: [],
      seriesWebPurchases: [],

      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },

          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#0273eb", "#ff9900", "#7ab240"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: true,
        },
        labels: ["Meta", "Amazon", "Google"],
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      },
      firstColumnColor: "#8B865F",
      columns: [
        { title: "Campaign", color: "#8B865F" },
        { title: "Data Source", color: "#ffffff" },
        { title: "Clicks", color: "#fd800d" },
        { title: "Impressions", color: "#2c5784" },
        { title: "Spends", color: "#26673d" },
        { title: "Conversions", color: "#f0f0f0" },
      ],
      slide: 0,
      summaryData: {
        clicks: 0,
        conversions: 0,
        costMicros: 0,
        impressions: 0,
        ctr: 0,
        avg_cpm: 0,
        cpc: 0,
        avg_cpc: 0,
        cost: 0,
        commonStore: 100,
        revenvue: 199200,
      },
      selectedClient: "",
      fetchClientsList: [],
      selectedSlug: "",
      isLoadingIndividualData: false,
      customIntialValue: true,
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
      selectedAdsOption: "all",
      selectedProjectsOption: "overall",
      selectedZoneTypeForFilter: [],
      selectedAffilatedForFilter: [],
      selectedAffilatedChannelForFilter: [],
      graphsWebPurchases: [],
    };
  },
  components: {
    vSelect,
    BTabs,
    BTab,
    BBadge,
    ToastificationContent,
    BPagination,
    BCarousel,
    BCarouselSlide,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BFormSelect,
    DateRangePicker,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueGoodTable,
    BCardTitle,
    apexchart: VueApexCharts,
    BOverlay,
    BLink,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    MultiHeaderProjectChannel,
    BIcon,
    BSpinner,
    Spinner,
    multiChannelSpinner,
    BFormGroup,
    BFormInput,
    BTable,
    BFormDatepicker,
    MultiHeader,
    TrendsTab,
    CreativeAnalysis,
    BFormRadio,
    ProductOutCome,
    AffilateInsights,
    TargetVsAchieved,
    ProjectOutXL,
  },
  methods: {
    getXLMonthDataChange(val) {
      this.selectedXLMonth = val;
    },
    getChangedTarget(val) {
      this.targetType = val;
    },
    getChangedProjectNameTargetCall(val) {
      this.getChangedProjectNameTarget = val;
    },
    getChangedChannelTypeTarget(val) {
      this.channelType = val;
    },
    fetchProjectOutComeXL() {
      this.projectOutComeXL = {};
      this.isXLDataFetched = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projectOutcome/data/?clientID=${this.selectedClient}&month=${this.selectedXLMonth}&year=${this.selectedXLYear}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.projectOutComeCol = response && response.data && response.data.maxCol;
          this.projectOutComeRow =  response && response.data && response.data.maxRow;
          this.projectOutComeXL =  response && response.data && response.data.data;
          this.isXLDataFetched = false;
        })
        .catch((error) => {
          this.isXLDataFetched = false;
          console.log(error);
        });
    },
    fetchTargetVsAchived() {
      this.targetVsAchivedData = [];
      this.isTargetValueFetched = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/targetVsAchieved/fetchTargetVsAchieved?clientID=${this.selectedClient}&targetType=${this.targetType}&channel=${this.channelType}&project=${this.getChangedProjectNameTarget}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.targetVsAchivedData = response.data;
          this.isTargetValueFetched = false;
        })
        .catch((error) => {
          this.isTargetValueFetched = false;
          console.log(error);
        });
    },
    CloseDataToZonewiseModal() {
      this.isCalulatedSuccessFully = false;
      this.addDataToZonewiseModal = false;
    },
    openDataToZonewiseModal(data, date) {
      this.selectedTargetVsAchivedVal = "";
      this.selectedZoneDate = "";
      this.selectedZoneDate = date;
      this.selectedTargetVsAchivedVal = data;
      this.fetchTargetValues();
      // if(this.targetCpl > 0 || this.targetLeads > 0 || this.targetLeads > 0){
      //   this.calculateTargetVsAchieved();
      // }
      this.addDataToZonewiseModal = true;
    },
    handleCallOutComeGetApiChange(data) {
      this.fetchProductOutComeData();
    },
    handleCallAffilatesGetApiChange(data) {
      this.fetchAffilatesComeData();
      this.getFetchAffilateOptions();
    },
    handleAffilatesGetApiChange(data) {
      this.fetchAffilatesComeData();
    },
    fetchAffilatesComeData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      let selectedAffilatesChannel =
        this.selectedAffilatedChannelForFilter.length === 0
          ? "all"
          : this.selectedAffilatedChannelForFilter;
      let selectedProjects =
        this.selectedAffilatedForFilter.length === 0
          ? "all"
          : this.selectedAffilatedForFilter;
      this.affilatesOutWiseData = [];
      this.fetchAffilatestOutWiseData = true;
      selectedProjects = encodeURIComponent(selectedProjects);
      selectedAffilatesChannel = encodeURIComponent(selectedAffilatesChannel);
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/affiliate/insights/?clientID=${this.selectedClient}${additionalQueryParams}&projects=${selectedProjects}&affiliatedChannel=${selectedAffilatesChannel}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.affilatesOutWiseData = response && response.data;
          this.fetchAffilatestOutWiseData = false;
        })
        .catch((error) => {
          console.log(error);
          this.fetchAffilatestOutWiseData = false;
        });
    },
    fetchProductOutComeData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.productOutWiseData = [];
      this.fetchProductOutWiseData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/project-outcome?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.productOutWiseData = response && response.data;
          this.fetchProductOutWiseData = false;
        })
        .catch((error) => {
          console.log(error);
          this.fetchProductOutWiseData = false;
        });
    },
    handleReportModeChange() {
      this.fetchZonewiseData();
      this.getFetchOptions();
    },
    changeCreativesProjectName(data) {
      this.CreativesProjectName = data;
    },
    changeCreativeMetricChange(data) {
      this.selectedCreativesMetricType = data;
    },
    changedChannelChange(data) {
      this.selectedCreativesChannelType = data;
    },
    getSourceData(data) {
      this.selectedSourceType = data;
    },
    getProductSourceData(data) {
      this.selectedProductSourceType = data;
    },
    getTrendsSourceData(data) {
      this.selectedTrendsSourceType = data;
    },
    getZoneTypeData(data) {
      this.selectedProductType = data;
    },
    getSubOptions(data) {
      this.subProductOptions = data;
    },
    handleZoneList(data) {
      this.zoneListChange = data;
    },
    handleProductProjectList(data) {
      this.productProjectNamesChange = data;
    },
    handledZoneType(data) {
      this.selectedZoneTypeForFilter = data;
    },
    handledAffilatesType(data) {
      this.selectedAffilatedForFilter = data;
    },
    handledAffilatesChannelType(data) {
      this.selectedAffilatedChannelForFilter = data;
    },
    changeTab(name) {
      this.tabMultiChannelName = name;
      if (this.tabMultiChannelName === "Overview") {
        this.reportModeSelected = "daily";
        this.fetchBenchMarkAllData();
        this.fetchRealTimeData();
        this.fetchSpends();
      }
      if (this.tabMultiChannelName === "ProjectInsights") {
        this.reportModeSelected = "daily";
        this.fetchProjectWiseData();
      }
      if (this.tabMultiChannelName === "TargetVSAchieved") {
        this.reportModeSelected = "daily";
        this.fetchTargetVsAchived();
      }
      if (this.tabMultiChannelName === "ProductOutCome") {
        this.reportModeSelected = "monthly";
        this.fetchProjectOutComeXL();
        this.fetchProductOutComeData();
      }
      if (this.tabMultiChannelName === "ZoneInsights") {
        this.reportModeSelected = "daily";
        this.fetchZonewiseData();
        this.getFetchOptions();
      }
      if (this.tabMultiChannelName === "AffiliateInsights") {
        this.reportModeSelected = "daily";
        this.fetchAffilatesComeData();
        this.getFetchAffilateOptions();
      }
      if (this.tabMultiChannelName === "ProductInsights") {
        this.reportModeSelected = "daily";
        this.getFetchOptions();
        this.fetchProjectProductWiseData();
      }
      if (this.tabMultiChannelName === "CreativeAnalysis") {
        this.reportModeSelected = "daily";
        this.getFetchOptions();
        this.getCreativesData();
      }
      if (this.tabMultiChannelName === "Trends") {
        this.reportModeSelected = "daily";
        this.getTrendsData();
        this.getTrendsTableData();
        this.getQualityVolumeTableData();
        this.getProductWiseForLaadsTableData();
      }
      if (this.tabMultiChannelName === "ChannelPerformanceOverview") {
        this.reportModeSelected = "daily";
        this.fetchOverViewData();
      }
    },
    getTrendsData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isTrendsDataFetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      this.TrendsData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/trends/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedTrendsSourceType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.TrendsData = response.data;
          this.isTrendsDataFetched = false;
        })
        .catch((error) => {
          this.TrendsData = [];
          console.log(error);
          this.isTrendsDataFetched = false;
        });
    },
    getQualityVolumeTableData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isQualityVolumeFetched = true;
      this.isQualityVolumeData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/subProductTrends?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedTrendsSourceType}&product=LeadGen&projects=all`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isQualityVolumeData = response.data;
          this.isQualityVolumeFetched = false;
        })
        .catch((error) => {
          this.isQualityVolumeData = [];
          console.log(error);
          this.isQualityVolumeFetched = false;
        });
    },
    getProductWiseForLaadsTableData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsFetched = true;
      this.LeadsData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/productWiseTrends?clientID=${this.selectedClient}${additionalQueryParams}&channel=all`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.LeadsData = response.data;
          this.isLeadsFetched = false;
        })
        .catch((error) => {
          this.LeadsData = [];
          console.log(error);
          this.isLeadsFetched = false;
        });
    },
    getTrendsTableData() {
      this.isTrendsTableDataFetched = true;
      this.TrendsTableDataFetched = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/productTrendChanges/?clientID=${this.selectedClient}&channel=${this.selectedTrendsSourceType}&tag=Zone&groupName=allZones`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.TrendsTableDataFetched = response.data;
          this.isTrendsTableDataFetched = false;
        })
        .catch((error) => {
          this.TrendsTableData = [];
          console.log(error);
          this.isTrendsTableDataFetched = false;
        });
    },
    getFetchAffilateOptions() {
      this.affilatesWiseOptions = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/affiliate-options?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          const combinedProjectArray = Object.values(
            response.data.Projects
          ).flat();
          this.affilatesAllOptions = [...combinedProjectArray];
          const combinedArray = Object.values(
            response.data.affiliateProjectMapping
          ).flat();
          this.affilatesWiseOptions = [...combinedArray];
          this.affilatesWiseOptionsIntial = this.affilatesWiseOptions[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFetchOptions() {
      this.creativeOptions = [];
      this.zoneWiseTypeOptionsFlag = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/options?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.zoneWiseTypeOptionsFlag = false;
          this.zoneWiseTypeOptions = response && response.data;
          const combinedArray = Object.values(
            this.zoneWiseTypeOptions.projectNames
          ).flat();
          this.intiallOptionsForGroup = [
            ...(response && response.data && response.data.groups),
          ];
          this.creativeOptions = [...combinedArray];
          this.productProjectIntialNamesChange = [...combinedArray];
          this.productWiseTypeOptions = [...combinedArray];
          this.initiallProjectName = this.creativeOptions[0];
          this.optionsLength = Object.keys(response && response.data).length;
        })
        .catch((error) => {
          this.zoneWiseTypeOptionsFlag = false;
          console.log(error);
        });
    },
    getCreativesData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isCreativesFlag = true;
      this.creativesData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      let channelType = this.selectedCreativesChannelType
        ? this.selectedCreativesChannelType
        : "all";
      let projectName = this.CreativesProjectName
        ? this.CreativesProjectName
        : "Atlantis";
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/creative-analysis/overall-category-insights?clientID=${this.selectedClient}&project=${projectName}${additionalQueryParams}&channel=${channelType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.creativesData = response && response.data;
          this.isCreativesFlag = false;
        })
        .catch((error) => {
          this.creativesData = [];
          console.log(error);
          this.isCreativesFlag = false;
        });
    },
    updateSpendsTransaction() {
      this.isEditSpends = true;
      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transaction/`,
          {
            clientID: this.selectedClient,
            transactionID: this.transactionID,
            amount: Number(this.editSpendsAmount),
            receivedAt: this.receivedDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchSpends();
          this.showToast(
            "Updated",
            "Spends Transaction updated successfully!",
            "success"
          );
          this.getTransactionList();
          this.closeEditSpendsModal();
          this.isEditSpends = false;
        })
        .catch((error) => {
          this.closeEditSpendsModal();
          this.isEditSpends = false;
          console.log(error);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    deleteASpends() {
      this.isDeleteSpends = true;
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transaction/?clientID=${this.selectedClient}&transactionID=${this.selectedTrasactionId}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isDeleteSpends = false;
          this.getTransactionList();
          this.fetchSpends();
          this.showToast(
            "Success!",
            "Delete Spends amount Successfully",
            "success"
          );
          this.closeDeleteSpendsModal();
        })
        .catch((error) => {
          this.isDeleteSpends = false;
          this.showToast("Error", "Unknown error occured.", "danger");
          this.closeDeleteSpendsModal();
        });
    },
    fetchSpends(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.isSpendsLoading = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-tracking/?clientID=${this.selectedClient}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.spendsTracker = response.data;
          this.spendsMessage = response.data && response.data.message;
          this.conditionalSpends =
            response.data &&
            response.data.result &&
            response.data.result.amountReceived;
          this.isSpendsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isSpendsLoading = false;
        });
    },
    calculateTargetVsAchieved() {
      this.isCalulatedSuccessFully = false;
      this.isCalculating = true;
      let startDate, endDate;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        startDate = prevWeek.toISOString().split("T")[0];
        endDate = yesterday.toISOString().split("T")[0];
      } else {
        startDate = this.startDateForAll;
        endDate = this.endDateForAll;
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/targetVsAcheived/`,
          {
            clientID: this.selectedClient,
            startDate: this.convertDate(this.selectedZoneDate),
            // endDate: endDate,
            channel: this.selectedSourceType,
            project: this.selectedTargetVsAchivedVal,
            targetedValues: {
              spends: this.targetSpends,
              leads: this.targetLeads,
              cpl: this.targetCpl,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let targetVal = response.data;
          if (targetVal && Object.keys(targetVal).length > 0) {
            this.targetCpl = targetVal.targetedValues.cpl;
            this.targetSpends = targetVal.targetedValues.spends;
            this.targetLeads = targetVal.targetedValues.leads;

            this.achievedCpl = targetVal.achievedValues.cpl;
            this.achievedSpends = targetVal.achievedValues.spends;
            this.achievedLeads = targetVal.achievedValues.leads;

            this.differenceCpl = targetVal.differenceValues.cpl;
            this.differenceSpends = targetVal.differenceValues.spends;
            this.differenceLeads = targetVal.differenceValues.leads;

            this.differencePerCpl = targetVal.differencePercentValues.cpl;
            this.differencePerSpends = targetVal.differencePercentValues.spends;
            this.differencePerLeads = targetVal.differencePercentValues.leads;

            this.isCalulatedSuccessFully = true;
          }
          this.isCalculating = false;
          this.showToast(
            "Success!",
            "Calculated Target Vs Achieved Successfully!",
            "success"
          );
        })
        .catch((error) => {
          this.isCalculating = false;
          this.isCalulatedSuccessFully = false;
          this.showToast("Error", "Unknown error occured!", "danger");
        });
    },
    convertDate(dateStr) {
      const [day, month, year] = dateStr.split("-");
      return `${year}-${month}-${day}`;
    },
    fetchTargetValues(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.isFetchTargetValue = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      let convertedIntiallDate = this.convertDate(this.selectedZoneDate);
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${convertedIntiallDate}`;
      }
      if (this.selectedZoneDate && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${convertedIntiallDate}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/targetedValues/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedSourceType}&project=${this.selectedTargetVsAchivedVal}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let targetVal = response.data;
          if (Object.keys(targetVal).length > 0) {
            this.targetCpl = targetVal.targetedValues.cpl;
            this.targetSpends = targetVal.targetedValues.spends;
            this.targetLeads = targetVal.targetedValues.leads;
            this.achievedCpl = targetVal.achievedValues.cpl;
            this.achievedSpends = targetVal.achievedValues.spends;
            this.achievedLeads = targetVal.achievedValues.leads;
          }
          this.isFetchTargetValue = false;
        })
        .catch((error) => {
          console.log(error);
          this.isFetchTargetValue = false;
        });
    },
    fetchZonewiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.zoneWiseData = [];
      this.isZonewiseDatafetched = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      let zoneListSeletion =
        this.zoneListChange.join(",") == ""
          ? this.creativeOptions
          : this.zoneListChange.join(",");
      let zoneData =
        this.selectedZoneTypeForFilter.length === 0
          ? this.intiallOptionsForGroup
          : this.selectedZoneTypeForFilter;
      let subProductOption = this.subProductOptions;
      // ${process.env.VUE_APP_SERVER_URL}/api/projects/group/insights/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedSourceType}&tag=Zone&product=${this.selectedProductType}&projects=${zoneListSeletion}&groupName=${zoneData}&subProductOption=${subProductOption}`,

      zoneListSeletion = encodeURIComponent(zoneListSeletion);

      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/group/insights/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedSourceType}&tag=Zone&product=${this.selectedProductType}&projects=${zoneListSeletion}&groupName=${zoneData}&subProductOption=${subProductOption}&frequency=${this.reportModeSelected}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.zoneWiseData = response && response.data;
          this.isZonewiseDatafetched = false;
        })
        .catch((error) => {
          console.log(error);
          this.isZonewiseDatafetched = false;
        });
    },
    fetchProjectProductWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      // this.zoneWiseData = []
      this.isProductDatafetched = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }

      let projectNames =
        this.productProjectNamesChange.length === 0
          ? this.productProjectIntialNamesChange
          : this.productProjectNamesChange;

      projectNames = encodeURIComponent(projectNames);

      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/products/insights/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedProductSourceType}&projects=${projectNames}&frequency=${this.reportModeSelected}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.productWiseData = response && response.data;
          // this.zoneWiseData = response && response.data
          this.isProductDatafetched = false;
        })
        .catch((error) => {
          console.log(error);
          this.isProductDatafetched = false;
        });
    },
    formatDateTime(value) {
      const date = new Date(value).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      return date;
    },
    getTransactionList() {
      this.spendsTrasactionFetch = true;
      this.spendsTrasactionData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transactions/?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.transactionStarts =
            response.data && response.data.trackingStartsAt;
          this.startTrackingDate =
            response.data && response.data.trackingStartsAt.length > 0
              ? response.data.trackingStartsAt
              : new Date().toISOString().substr(0, 10);
          this.spendsAmountOnTop = response.data && response.data.totalAmount;
          this.spendsCurrencyOnTop =
            response.data && response.data.currencyCode;
          this.spendsTrasactionData =
            response.data && response.data.transactions;
          this.spendsTrasactionFetch = false;
        })
        .catch((error) => {
          console.log(error);
          this.spendsTrasactionFetch = false;
        });
    },
    addTransaction() {
      this.addingSpends = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transaction`,
          {
            clientID: this.selectedClient,
            amount: this.spendsAmount,
            receivedAt: this.receivedDate,
            trackingStartsAt: this.startTrackingDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.getTransactionList();
          this.fetchSpends();
          this.spendsAmount = 0;
          this.receivedDate = new Date().toISOString().substr(0, 10);
          this.startTrackingDate = new Date().toISOString().substr(0, 10);
          this.addingSpends = false;
          this.showToast(
            "Success!",
            "Added Spends amount Successfully",
            "success"
          );
        })
        .catch((error) => {
          this.addingSpends = false;
          this.showToast("Error", "Unknown error occured!", "danger");
        });
    },
    closeDeleteSpendsModal() {
      this.spendsDeleteModal = false;
    },
    openDeleteSpendsModal(item) {
      this.selectedTrasactionId = item.item.transactionID;
      this.spendsDeleteModal = true;
    },
    closeAddSpendsModal() {
      this.addSpendsModal = false;
    },
    closeEditSpendsModal() {
      this.editSpendsModal = false;
    },
    openEditSpendsModal(item) {
      this.editSpendsAmount = item.item.amount;
      this.transactionID = item.item.transactionID;
      this.receivedDate = item.item.receivedAt;
      this.editSpendsModal = true;
    },
    openAddSpendsModal() {
      this.getTransactionList();
      this.addSpendsModal = true;
    },
    updateReportID() {
      const reportTypeKey = this.selectedReportType.toLowerCase();
      if (reportTypeKey == "weekly") {
        let report = this.client.googleSheetReports.weekly;
        this.selectedReportID = report;
        return;
      }
      let reportID = this.client.googleSheetReports[reportTypeKey][
        `${this.selectedReportMonth}-${this.selectedYear}`
      ];
      this.selectedReportID = reportID;
    },
    totalOverviewData(data) {
      return (this.filterDataLength = data);
    },
    orderedLinks() {
      const links = [
        {
          name: "googleAds",
          to: `/client/${this.client.slug}/dashboard/google-ads`,
          connected: this.googleAdsConnected,
          disabled: !this.googleAdsConnected,
          imageSrc: "../../assets/images/logo/ads-logo.png",
        },
        {
          name: "amazonAds",
          to: `/client/${this.client.slug}/dashboard/amazon-ads`,
          connected: this.amazonAdsConnected,
          disabled: !this.amazonAdsConnected,
          imageSrc: "../../assets/images/logo/amazon.png",
        },
        {
          name: "facebookAds",
          to: `/client/${this.client.slug}/dashboard/meta-ads`,
          connected: this.facebookAdsConnected,
          disabled: !this.facebookAdsConnected,
          imageSrc: "../../assets/images/logo/meta.png",
        },
        {
          name: "googleAnalytics",
          to: `/client/${this.client.slug}/dashboard/google-analytics`,
          connected: this.analyticsConnected,
          disabled: !this.analyticsConnected,
          imageSrc: "../../assets/images/portrait/small/analytics-avatar.png",
        },
        {
          name: "gmb",
          to: `/gmb/${this.client.slug}`,
          connected: this.gmbConnected,
          disabled: !this.gmbConnected,
          imageSrc: "../../assets/images/logo/gmb.jpeg",
        },
        {
          name: "shopifyAds",
          to: `/stores/${this.client.slug}`,
          connected: this.shopifyAdsConnected,
          disabled: !this.shopifyAdsConnected,
          imageSrc: "../../assets/images/logo/Shopify.png",
        },
      ];

      // Move disabled links to the end
      const orderedLinks = links.sort((a, b) => {
        if (a.connected && !b.connected) return -1;
        if (!a.connected && b.connected) return 1;
        return 0;
      });

      return orderedLinks;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    updateChartData() {
      let conversionsLabel = "";
      if (this.client.businessType[0] == "leadgen") {
        conversionsLabel = "Leads";
        this.seriesConversionsBenchMark = [
          {
            name: conversionsLabel,
            data: this.conversionsArray,
          },
        ];
      } else if (this.client.businessType[0] == "ecommerce") {
        conversionsLabel = "Purchases";
        this.seriesConversionsBenchMark = [
          {
            name: conversionsLabel,
            data: this.graphsWebPurchases,
          },
        ];
      } else {
        this.seriesConversionsBenchMark = [
          {
            name: conversionsLabel,
            data: this.conversionsArray,
          },
        ];
        conversionsLabel = "Conversions";
      }
      this.chartOptionsBench = {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: $trackBgColor,
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -30,
            bottom: -12,
          },
        },
        stroke: {
          width: 3,
        },
        colors: ["#7ab240"],
        markers: {
          size: 2,
          colors: "#7ab240",
          strokeColors: "#7ab240",
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              fillColor: "#ffffff",
              strokeColor: "#7ab240",
              size: 5,
            },
          ],
          shape: "circle",
          radius: 2,
          hover: {
            size: 3,
          },
        },
        xaxis: {
          categories: this.datesArray,
          labels: {
            show: true,
            style: {
              fontSize: "0px",
            },
          },
        },
        yaxis: {
          show: false,
        },
      };

      this.seriesCostBenchMark = [
        {
          name: "Spends",
          data: this.costArray,
        },
      ];
    },
    updateBenchMarkData() {
      this.seriesClicks = [
        parseFloat(this.metaClicks),
        parseFloat(this.amazonClicks),
        parseFloat(this.googleClicks),
      ];
      this.seriesSpends = [
        parseFloat(this.metaSpends),
        parseFloat(this.amazonSpends),
        parseFloat(this.googleSpends),
      ];
      this.seriesImpressions = [
        parseFloat(this.metaImpressions),
        parseFloat(this.amazonImpressions),
        parseFloat(this.googleImpressions),
      ];
      this.seriesConversions = [
        parseFloat(this.metaConversion),
        parseFloat(this.amazonConversion),
        parseFloat(this.googleConversion),
      ];
      this.seriesWebPurchases = [
        parseFloat(this.metaWebPurchases),
        parseFloat(this.amazonConversion),
        parseFloat(this.googleConversion),
      ];
      this.seriesRevenue = [
        parseFloat(this.metaRevenue),
        parseFloat(this.amazonRevenue),
        parseFloat(this.googleRevenue),
      ];
      this.seriesCPL = [
        parseFloat(this.metaCPL),
        parseFloat(this.amazonCPL),
        parseFloat(this.googleCPL),
      ];
      this.seriesCTR = [
        parseFloat(this.metaCTR),
        parseFloat(this.amazonCRT),
        parseFloat(this.googleCRT),
      ];
    },
    getCellStyle(columnColor, value) {
      if (value === 0) {
        return `background-color: white; text-align: center; color: #000000;`;
      }
      const ranges = [
        { min: 1, max: 999999 },
        { min: 100000, max: 99999999 },
      ];
      let opacity = 0;
      let rgbColor = "";
      for (const range of ranges) {
        if (value >= range.min && value <= range.max) {
          const rangeSize = range.max - range.min;
          opacity = (value - range.min) / rangeSize;
          opacity = opacity * 0.5 + 0.5;
          opacity = opacity.toFixed(2);
          rgbColor = this.hexToRgb(columnColor);
          break;
        }
      }
      return `background-color: rgba(${rgbColor}, ${opacity}); text-align: center; color: #555555;`;
    },
    formatNumberToTwoDecimals(number, decimal = 2) {
      try {
        if (number == undefined || number == null) {
          number = 0;
        }
        return new Intl.NumberFormat("en-IN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: decimal,
        }).format(number);
      } catch (err) {
        return 0;
      }
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, "");
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let endDate = moment(values.endDate).format("YYYY-MM-DD");
      this.startDateForAll = startDate;
      this.endDateForAll = endDate;
      if (this.tabMultiChannelName === "Overview") {
        this.fetchBenchMarkAllData();
        this.fetchRealTimeData();
        this.fetchSpends();
      }
      if (
        this.client.accessConfiguration &&
        this.client.accessConfiguration.projects &&
        this.client.accessConfiguration.projects.enabled
      ) {
        if (this.tabMultiChannelName === "ProjectInsights") {
          this.fetchProjectWiseData();
          this.getProductWiseForLaadsTableData();
        }
        if (this.tabMultiChannelName === "ZoneInsights") {
          this.fetchZonewiseData();
          this.getFetchOptions();
        }
        if (this.tabMultiChannelName === "AffiliateInsights") {
          this.fetchAffilatesComeData();
          this.getFetchAffilateOptions();
        }
        if (this.tabMultiChannelName === "ProductInsights") {
          this.fetchProjectProductWiseData();
          this.getFetchOptions();
        }
        if (this.tabMultiChannelName === "CreativeAnalysis") {
          this.getFetchOptions();
          this.getCreativesData();
        }
        if (this.tabMultiChannelName === "Trends") {
          this.getTrendsData();
          this.getTrendsTableData();
          this.getQualityVolumeTableData();
          this.getProductWiseForLaadsTableData();
        }
        if (this.tabMultiChannelName === "ChannelPerformanceOverview") {
          this.fetchOverViewData();
        }
      }
    },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    setIntialClient() {
      this.selectedClient = this.client._id;
    },
    getClient() {
      const slug = this.$route.params.slug;
      this.idClientEntireLoad = true;
      this.showSummaryDashboardLoading = true;
      (this.googleAdsConnected = false),
        (this.amazonAdsConnected = false),
        (this.facebookAdsConnected = false),
        (this.googleAdsId = "");
      this.metaAdsId = "";
      this.amazonAdsId = "";
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          (this.googleAdsConnected = this.client.integrations.googleAds),
            (this.amazonAdsConnected = this.client.integrations.amazon),
            (this.facebookAdsConnected = this.client.integrations.facebook),
            (this.gmbConnected = this.client.integrations.gmb),
            (this.analyticsConnected = this.client.integrations.google),
            (this.shopifyAdsConnected = this.client.integrations.shopify);

          this.googleAdsId = response.data.client.googleAds.customerClientID;

          this.metaAdsId = response.data.client.facebook.adAccountIDs;

          this.amazonAdsId = response.data.client.amazon.profileIDs;

          this.selectedReportID =
            response.data.client.googleSheetReports.daily &&
            response.data.client.googleSheetReports.daily[
              Object.keys(response.data.client.googleSheetReports.daily)
                .sort()
                .pop()
            ];
          this.idClientEntireLoad = false;
          this.breadcrumbs = [
            {
              text: "Overview",
              to: "/dashboard",
            },
          ];
          this.setIntialClient();
          this.getFetchOptions();
          //     if(this.client.accessConfiguration && this.client.accessConfiguration.projects && this.client.accessConfiguration.projects.enabled){
          //     // this.fetchProjectProductWiseData();
          //     // this.fetchZonewiseData();
          //     // this.getTrendsData();
          //     // this.fetchProjectWiseData();
          //     // this.getQualityVolumeTableData();
          //     // this.getProductWiseForLaadsTableData();
          //     // this.getTrendsTableData();
          //     // this.getFetchOptions();
          //     if(this.tabMultiChannelName = "ProjectInsights"){
          //   this.fetchProjectWiseData();
          //   this.getProductWiseForLaadsTableData();
          // }
          // if(this.tabMultiChannelName = "ZoneInsights"){
          //   this.fetchZonewiseData();
          //   this.getFetchOptions();
          // }
          // if(this.tabMultiChannelName = "ProductInsights"){
          //   this.fetchProjectProductWiseData()
          // }
          // if(this.tabMultiChannelName = "Trends"){
          //   this.getTrendsData();
          //   this.getTrendsTableData();
          //   this.getQualityVolumeTableData();
          // }
          //     }
          if ((this.tabMultiChannelName = "Overview")) {
            this.fetchBenchMarkAllData();
            this.fetchRealTimeData();
            this.fetchSpends();
          }

          // if(this.tabMultiChannelName = "ChannelPerformanceOverview"){
          //   this.fetchOverViewData()
          // }
          // this.fetchZonewiseData();
          // this.getTrendsData();
          // this.getQualityVolumeTableData();
          // this.getProductWiseForLaadsTableData()
          // this.getTrendsTableData();
          // this.fetchProjectProductWiseData();

          // this.getFetchOptions();
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: "/client",
            active: true,
          });
          this.showSummaryDashboardLoading = false;
        })
        .catch((error) => {
          this.idClientEntireLoad = false;
          console.log(error.response.data);
          this.showSummaryDashboardLoading = false;
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    fetchRealTimeData() {
      // this.showSummaryDashboardLoading = true;
      this.isLiveDataIsLoad = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/realtime?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchRealTime = response.data;
          if (this.fetchRealTime.google) {
            this.googleConversionToday = this.fetchRealTime.google.conversions.today;
            this.googleConversionYeserday = this.fetchRealTime.google.conversions.yesterday;
            this.googleConversionDelta = this.fetchRealTime.google.conversions.delta;
            this.googleSpendsToday = this.fetchRealTime.google.spends.today;
            this.googleSpendsYeserday = this.fetchRealTime.google.spends.yesterday;
            this.googleSpendsDelta = this.fetchRealTime.google.spends.delta;
            this.googleWebPurchasesToday = this.fetchRealTime.google.webPurchases.today;
            this.googleWebPurchasesYeserday = this.fetchRealTime.google.webPurchases.yesterday;
            this.googleWebPurchasesDelta = this.fetchRealTime.google.webPurchases.delta;
          }
          if (this.fetchRealTime.amazon) {
            this.amazonConversionToday = this.fetchRealTime.amazon.conversions.today;
            this.amazonConversionYeserday = this.fetchRealTime.amazon.conversions.yesterday;
            this.amazonConversionDelta = this.fetchRealTime.amazon.conversions.delta;
            this.amazonSpendsToday = this.fetchRealTime.amazon.spends.today;
            this.amazonSpendsYeserday = this.fetchRealTime.amazon.spends.yesterday;
            this.amazonSpendsDelta = this.fetchRealTime.amazon.spends.delta;
            this.amazonWebPurchasesToday = this.fetchRealTime.amazon.webPurchases.today;
            this.amazonWebPurchasesYeserday = this.fetchRealTime.amazon.webPurchases.yesterday;
            this.amazonWebPurchasesDelta = this.fetchRealTime.amazon.webPurchases.delta;
          }
          if (this.fetchRealTime.Meta) {
            this.metaConversionToday = this.fetchRealTime.Meta.conversions.today;
            this.metaConversionYeserday = this.fetchRealTime.Meta.conversions.yesterday;
            this.metaConversionDelta = this.fetchRealTime.Meta.conversions.delta;
            this.metaSpendsToday = this.fetchRealTime.Meta.spends.today;
            this.metaSpendsYeserday = this.fetchRealTime.Meta.spends.yesterday;
            this.metaSpendsDelta = this.fetchRealTime.Meta.spends.delta;
            this.metaWebPurchasesToday = this.fetchRealTime.Meta.webPurchases.today;
            this.metaWebPurchasesYeserday = this.fetchRealTime.Meta.webPurchases.yesterday;
            this.metaWebPurchasesDelta = this.fetchRealTime.Meta.webPurchases.delta;
          }
          // this.showSummaryDashboardLoading = false;
          this.isLiveDataIsLoad = false;
        })
        .catch((error) => {
          this.isLiveDataIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    fetchProjectWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.showSummaryDashboardLoading = true;
      this.isProjectWiseIsLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/overall-project-insights?clientID=${this.selectedClient}&channel=all&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.projectWiseData = response.data;
          if (Object.keys(this.projectWiseData).length == 0) {
            this.hideOverallProjectsTab = true;
          }
          this.isProjectWiseIsLoad = false;
        })
        .catch((error) => {
          this.isProjectWiseIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    fetchOverViewData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.showSummaryDashboardLoading = true;
      this.projectOverViewLoad = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/performance_overview?clientID=${this.selectedClient}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchOverViewAllData = response.data;
          this.updateChartData();
          this.updateBenchMarkData();
          this.showSummaryDashboardLoading = false;
          this.projectOverViewLoad = false;
        })
        .catch((error) => {
          this.showSummaryDashboardLoading = false;
          this.projectOverViewLoad = false;
          console.log(error);
        });
    },
    fetchBenchMarkAllData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.costArray = [];
      this.isRevenueEmpty = true;
      this.datesArray = [];
      this.conversionsArray = [];
      this.showSummaryDashboardLoadingGraph = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/benchmark?clientID=${this.selectedClient}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchBenchMarkAllDataValue = response.data.dateWise.datewise;
          const datesArray = Object.keys(this.fetchBenchMarkAllDataValue);
          const datesAllArray = [];
          const totalCostArray = [];
          const totalConversionsArray = [];
          const totalWebPurchases = [];
          const dataArray = datesArray.map((date) => {
            const totalData = this.fetchBenchMarkAllDataValue[date].total;
            datesAllArray.push(date);
            const totalCost = parseFloat(totalData.cost);
            const totalConversions = parseFloat(totalData.conversions);
            const totalBenchWebConversions = parseFloat(totalData.webPurchase);
            totalWebPurchases.push(totalBenchWebConversions);
            totalCostArray.push(totalCost);
            totalConversionsArray.push(totalConversions);
            return {
              date,
              total: { ...totalData },
            };
          });
          this.costArray = totalCostArray;
          this.datesArray = datesAllArray;
          this.conversionsArray = totalConversionsArray;
          this.graphsWebPurchases = totalWebPurchases;
          this.chartDataValues;
          this.fetchBenchMarkDataValue = response.data.metricWise;
          this.googleConversion = this.fetchBenchMarkDataValue.conversions.google;
          this.googleSpends = this.fetchBenchMarkDataValue.spends.google;
          this.googleImpressions = this.fetchBenchMarkDataValue.impressions.google;
          this.googleClicks = this.fetchBenchMarkDataValue.clicks.google;
          this.googleWebPurchases = this.fetchBenchMarkDataValue.webPurchase.google;
          this.clicksTotal = this.fetchBenchMarkDataValue.clicks.total;
          this.webPurchasesTotal = this.fetchBenchMarkDataValue.webPurchase.total;
          this.metaConversion = this.fetchBenchMarkDataValue.conversions.meta;
          this.metaSpends = this.fetchBenchMarkDataValue.spends.meta;
          this.metaImpressions = this.fetchBenchMarkDataValue.impressions.meta;
          this.metaClicks = this.fetchBenchMarkDataValue.clicks.meta;
          this.metaWebPurchases = this.fetchBenchMarkDataValue.webPurchase.meta;
          this.amazonConversion =
            this.fetchBenchMarkDataValue.conversions.amazon == "NA"
              ? 0
              : this.fetchBenchMarkDataValue.conversions.amazon;
          this.amazonSpends = this.fetchBenchMarkDataValue.spends.amazon;
          this.amazonImpressions = this.fetchBenchMarkDataValue.impressions.amazon;
          this.amazonClicks = this.fetchBenchMarkDataValue.clicks.amazon;
          // this.impressionsTotal = this.fetchBenchMarkDataValue.impressions.total
          this.impressionsTotal = this.fetchBenchMarkDataValue.impressions.total;
          this.spendsTotal = this.fetchBenchMarkDataValue.spends.total;
          this.conversionsTotal = this.fetchBenchMarkDataValue.conversions.total;
          this.revenueTotal = this.fetchBenchMarkDataValue.revenue.total;
          this.cplTotal = this.fetchBenchMarkDataValue.cpl.total;
          this.metaCPL = this.fetchBenchMarkDataValue.cpl.meta;
          this.amazonCPL = this.fetchBenchMarkDataValue.cpl.amazon;
          this.googleCPL = this.fetchBenchMarkDataValue.cpl.google;
          this.ctrTotal = this.fetchBenchMarkDataValue.ctr.total;
          this.metaCTR = this.fetchBenchMarkDataValue.ctr.meta;
          this.amazonCRT = this.fetchBenchMarkDataValue.ctr.amazon;
          this.googleCRT = this.fetchBenchMarkDataValue.ctr.google;
          if (this.revenueTotal == 0) {
            this.isRevenueEmpty = false;
          }
          this.metaRevenue = this.fetchBenchMarkDataValue.revenue.meta;
          this.googleRevenue = this.fetchBenchMarkDataValue.revenue.google;
          this.amazonRevenue = this.fetchBenchMarkDataValue.revenue.amazon;
          this.updateChartData();
          this.updateBenchMarkData();
          this.showSummaryDashboardLoadingGraph = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSummaryDashboardLoadingGraph = false;
        });
    },
    fetchMultiChannelDashboardData() {
      this.fetchBenchMarkAllData();
      if (
        this.client.accessConfiguration &&
        this.client.accessConfiguration.projects &&
        this.client.accessConfiguration.projects.enabled
      ) {
        this.fetchProjectProductWiseData();
        this.fetchZonewiseData();
        this.fetchAffilatesComeData();
        this.getTrendsData();
        this.fetchProjectWiseData();
        this.fetchProductOutComeData();
        this.getQualityVolumeTableData();
        this.getProductWiseForLaadsTableData();
        this.getTrendsTableData();
        this.getFetchOptions();
        this.getCreativesData();
      }
      this.fetchOverViewData();
      this.fetchRealTimeData();
      // this.fetchZonewiseData();
      // this.getTrendsData();
      // this.getQualityVolumeTableData();
      // this.getProductWiseForLaadsTableData()
      // this.getTrendsTableData();
      // this.fetchProjectProductWiseData();
      // this.updateChartData();
      // this.updateBenchMarkData();
    },
    prevSlide() {
      this.slide = (this.slide - 1 + 3) % 3;
    },
    nextSlide() {
      this.slide = (this.slide + 1) % 3;
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  computed: {
    dynamicStyle() {
      return this.tabMultiChannelName === "ZoneInsights" ||
        this.tabMultiChannelName === "ProductOutCome"
        ? { marginTop: "-10px" }
        : {};
    },
    getChannelOverviewData() {
      let columns = [];
      if (this.client.businessType[0] === "leadgen") {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: "Clicks", color: "#fd800d" },
          { title: "Impressions", color: "#2c5784" },
          { title: "Spends", color: "#26673d" },
          { title: "Leads", color: "#f0f0f0" },
        ];
      } else if (this.client.businessType[0] === "ecommerce") {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: "Clicks", color: "#fd800d" },
          { title: "Impressions", color: "#2c5784" },
          { title: "Spends", color: "#26673d" },
          { title: "Purchases", color: "#f0f0f0" },
        ];
      } else {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: "Clicks", color: "#fd800d" },
          { title: "Impressions", color: "#2c5784" },
          { title: "Spends", color: "#26673d" },
          { title: "Conversions", color: "#f0f0f0" },
        ];
      }
      return columns;
    },
    maxDate() {
      const today = new Date();
      return today.toISOString().split("T")[0]; // Convert to ISO date string
    },
    startTrackingDateConditionally() {
      return this.transactionStarts && this.transactionStarts.length > 0
        ? this.transactionStarts
        : this.startTrackingDate;
    },
    isTrackingDateDisabled() {
      return !this.filteredDataBasedOnAct;
    },
    isCreateButtonDisabled() {
      return this.spendsAmount <= 0;
    },
    isEditButtonDisabled() {
      return this.editSpendsAmount <= 0;
    },
    processedData() {
      // Add the "extraField" to each item with the fixed value "aks"
      return this.spendsTrasactionData.map((item) => {
        return {
          ...item,
          // extraField:  this.transactionStarts
        };
      });
    },
    filteredDataBasedOnIsActive() {
      return this.processedData.filter((item) => item.isActive);
    },
    filteredDataBasedOnAct() {
      const allInactive = this.processedData.every((item) => !item.isActive);
      return allInactive;
    },
    dynamicArray() {
      const dynamicArray = [];
      const dailyReports = this.client.googleSheetReports.daily;
      const months = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ];

      // Iterate through the keys (dates) of the dailyReports object
      for (const date in dailyReports) {
        if (Object.hasOwnProperty.call(dailyReports, date)) {
          const [month, year] = date.split("-");
          const monthObj = months.find((m) => m.value.toString() === month);
          if (monthObj) {
            dynamicArray.push({
              label: monthObj.label,
              value: parseInt(month),
            });
          }
        }
      }
      dynamicArray.sort((a, b) => a.value - b.value);
      return dynamicArray;
    },
    optionsAds() {
      const ads = [];
      // Check if the client data exists and is not empty
      if (this.client && Object.keys(this.client).length > 0) {
        const { integrations } = this.client;
        let count = 0;
        if (integrations.googleAds) count++;
        if (integrations.facebook) count++;
        if (integrations.amazon) count++;
        // If there are at least two integrations, populate the ads array
        if (count >= 2) {
          this.selectedAdsOption = "all";
          ads.push({ value: "all", text: "All" });
          if (integrations.googleAds) {
            ads.push({ value: "google-ads", text: "Google Ads" });
          }
          if (integrations.facebook) {
            ads.push({ value: "meta-ads", text: "Meta Ads" });
          }
          if (integrations.amazon) {
            ads.push({ value: "amazon-ads", text: "Amazon Ads" });
          }
        } else {
          if (integrations.googleAds) {
            this.selectedAdsOption = "google-ads";
            ads.push({ value: "google-ads", text: "Google Ads" });
          }
          if (integrations.facebook) {
            this.selectedAdsOption = "meta-ads";
            ads.push({ value: "meta-ads", text: "Meta Ads" });
          }
          if (integrations.amazon) {
            this.selectedAdsOption = "amazon-ads";
            ads.push({ value: "amazon-ads", text: "Amazon Ads" });
          }
        }
      }
      return ads;
    },
    // optionsCampaignNamses() {
    // return this.fetchOverViewAllData.map(entry => entry.campaign);
    //  },
    getCols() {
      const integrations = this.client.integrations;
      if (this.googleAdsConnected) {
        if (
          this.googleAdsConnected &&
          this.amazonAdsConnected &&
          this.facebookAdsConnected
        ) {
          return "4";
        } else if (this.googleAdsConnected && this.amazonAdsConnected) {
          return "3";
        } else if (this.googleAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else if (this.amazonAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else {
          return "4";
        }
      } else {
        return "";
      }
    },
    getReportsCols() {
      if (this.selectedReportType == "Weekly") {
        return "5";
      }
      if (this.selectedReportType == "Daily") {
        return "3";
      }
    },
    getCardCols() {
      const integrations = this.client.integrations;
      if (this.googleAdsConnected) {
        if (
          this.googleAdsConnected &&
          this.amazonAdsConnected &&
          this.facebookAdsConnected
        ) {
          return "6";
        } else if (this.googleAdsConnected && this.amazonAdsConnected) {
          return "3";
        } else if (this.googleAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else if (this.amazonAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else if (this.amazonAdsConnected) {
          return "4";
        } else if (this.googleAdsConnected) {
          return "4";
        } else if (this.facebookAdsConnected) {
          return "4";
        } else {
          return "3";
        }
      } else {
        return "3";
      }
    },
    chartDataValues() {
      let data = this.fetchBenchMarkAllDataValue;
    },
    displayedOverviewItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      let filteredData;
      switch (this.selectedAdsOption) {
        case "google-ads":
          filteredData = this.fetchOverViewAllData.filter(
            (item) => item.source === "google"
          );
          break;
        case "meta-ads":
          filteredData = this.fetchOverViewAllData.filter(
            (item) => item.source === "meta"
          );
          break;
        case "amazon-ads":
          filteredData = this.fetchOverViewAllData.filter(
            (item) => item.source === "amazon"
          );
          break;
        default:
          filteredData = this.fetchOverViewAllData;
          break;
      }
      if (
        this.searchByCampaignName &&
        this.searchByCampaignName.trim() !== ""
      ) {
        filteredData = filteredData.filter((item) =>
          item.campaign
            .toLowerCase()
            .includes(this.searchByCampaignName.toLowerCase())
        );
      }
      // if (this.selectedFilterCampaign && this.selectedFilterCampaign.length > 0) {
      //   filteredData = filteredData.filter(
      //     item => this.selectedFilterCampaign.includes(item.campaign)
      //   );
      // }
      this.totalOverviewData(filteredData.length);
      return filteredData.slice(start, end);
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, today],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
  },
  watch: {
    selectedXLMonth(newVal, oldVal) {
      this.selectedXLMonth = 0;
      this.selectedXLMonth = newVal;
      this.fetchProjectOutComeXL();
    },
    getChangedProjectNameTarget(newVal, oldVal) {
      this.getChangedProjectNameTarget = "";
      this.getChangedProjectNameTarget = newVal;
      this.fetchTargetVsAchived();
    },
    targetType(newVal, oldVal) {
      this.targetType = "";
      this.targetType = newVal;
      this.fetchTargetVsAchived();
    },
    channelType(newVal, oldVal) {
      this.channelType = "";
      this.channelType = newVal;
      this.fetchTargetVsAchived();
    },
    CreativesProjectName(newVal, oldVal) {
      this.CreativesProjectName = "";
      this.CreativesProjectName = newVal;
      this.getCreativesData();
    },
    // selectedCreativesMetricType(newVal, oldVal) {
    //   this.selectedCreativesMetricType = ""
    //   this.selectedCreativesMetricType = newVal
    //   this.getCreativesData()
    // },
    selectedCreativesChannelType(newVal, oldVal) {
      this.selectedCreativesChannelType = "";
      this.selectedCreativesChannelType = newVal;
      this.getCreativesData();
    },
    selectedAffilatedForFilter(newVal, oldVal) {
      this.selectedAffilatedForFilter = newVal;
      this.fetchAffilatesComeData();
    },
    selectedAffilatedChannelForFilter(newVal, oldVal) {
      this.selectedAffilatedChannelForFilter = newVal;
      this.fetchAffilatesComeData();
    },
    selectedZoneTypeForFilter(newVal, oldVal) {
      this.selectedZoneTypeForFilter = newVal;
      this.zoneListChange = [];
      this.zoneWiseData = [];
      this.fetchZonewiseData();
    },
    zoneListChange(newVal, oldVal) {
      this.zoneListChange = newVal;
      this.fetchZonewiseData();
    },
    productProjectNamesChange(newVal, oldVal) {
      this.productProjectNamesChange = newVal;
      this.fetchProjectProductWiseData();
    },
    selectedSourceType(newVal, oldVal) {
      this.selectedSourceType = "";
      this.selectedSourceType = newVal;
      this.fetchZonewiseData();
    },
    selectedProductSourceType(newVal, oldVal) {
      this.selectedProductSourceType = "";
      this.selectedProductSourceType = newVal;
      console.log(newVal);
      this.fetchProjectProductWiseData();
    },
    selectedTrendsSourceType(newVal, oldVal) {
      this.selectedTrendsSourceType = "";
      this.selectedTrendsSourceType = newVal;
      // this.getTrendsTableData()
      // this.getQualityVolumeTableData();
      // this.getProductWiseForLaadsTableData();
      this.getTrendsData();
    },
    selectedProductType(newVal, oldVal) {
      this.selectedProductType = "";
      this.selectedProductType = newVal;
      this.fetchZonewiseData();
    },
    subProductOptions(newVal, oldVal) {
      this.subProductOptions = "";
      this.subProductOptions = newVal;
      this.fetchZonewiseData();
    },
  },
  mounted() {
    this.tabMultiChannelName = "Overview";
    this.getClient();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.infoIconLive {
  cursor: pointer;
  margin-left: 0px !important;
}
.custom-tooltip {
  text-align: left;
  width: 500px;
  font-size: 11.5px;
  padding: 0px;
}
.multichanneldashbpa {
  .nav-tabs .nav-link {
    border: none;
    padding: 4px !important;
    border-radius: 0;
    font-size: 14px;
  }
}

.multi-range-select {
  margin-bottom: 0px !important;
}
.checkbox-weekly {
  width: 14px !important;
  height: 14px !important;
}
.text-red {
  color: #b4b4b8;
  font-weight: bold;
}
.blink-soft {
  animation: blinker 1.2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

// Date selector UI break fixes.
.multiDateSelector {
  .daterangepicker .calendar-table td,
  .daterangepicker .calendar-table th {
    white-space: nowrap;
    vertical-align: middle;
    min-width: 44px !important;
    width: 41px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  .daterangepicker .monthselect {
    margin-right: 0.2rem;
    margin-left: -1.2rem;
  }
  .daterangepicker.show-ranges.show-weeknumbers[data-v-1ebd09d2],
  .daterangepicker.show-ranges[data-v-1ebd09d2] {
    min-width: 840px !important;
  }
  .daterangepicker.ltr .drp-calendar.left {
    clear: left !important;
    margin-right: 15% !important;
  }
}
.multiHeaderMulti {
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  .card-body {
    padding: 1rem !important;
  }
}

.multiHeaderMultihide {
  display: none;
}

.screenAlignmentMultiChannel {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}

.animated-message-text {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.animated-message-text span {
  position: absolute;
  width: 90%;
  font-size: 12px;
  font-weight: 600;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #553c9a,
    #ee4b2b,
    #00c2cb,
    #ff7f50,
    #553c9a
  );
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 200%;
  background-position: -200%;
  animation: animated-gradient 2s infinite alternate-reverse;
}

@keyframes animated-gradient {
  to {
    background-position: 200%;
  }
}

.transaction-table {
  overflow-x: auto;
}

.custom-spends-table th,
.custom-spends-table td {
  font-size: 12px;
}

#datepicker-sm-spends,
#datepicker-sm-tracking {
  height: 36px !important;
  /* Adjust the height as needed */
}

.b-form-btn-label-control.form-control > label {
  padding-left: 0.25rem !important;
  margin-top: 5px !important;
}

.transaction-table {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .custom-table {
    border: none;
  }

  .action-buttons {
    display: flex;
    gap: 10px;
  }

  .action-icon {
    cursor: pointer;
    color: #007bff;
    /* Blue color for icons */
  }

  .action-icon:hover {
    color: #0056b3;
    /* Darker blue color on hover */
  }
}

.spendsTrackerContainer {
  .card-body {
    padding: 0.4rem !important;
  }
}

.custom-report-select:disabled {
  background-color: #f8f9fa;
  /* Light gray background */
  color: #6c757d;
  /* Gray text color */
  cursor: not-allowed;
  /* Change cursor to indicate not interactive */
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Set the height of the container */
}

.dotLive {
  margin-left: 5px;
  margin-right: 5px;
}

.blink_text {
  color: black;
  animation-name: blinker;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.disabledRanges {
  pointer-events: none;
  opacity: 0.6;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.disabledRanges .picker-controller {
  pointer-events: none;
}

.disabledRanges .mr-1 {
  pointer-events: none;
}
@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.todayText {
  margin-top: 35px !important;
  margin-left: -81%;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: left !important;
  align-items: left;
  font-weight: bold;
}

.todayCardContainer {
  .card-body {
    padding: 0.2rem !important;
  }
}

.multichannelCardfilter {
  .card-body {
    padding: 1rem !important;
  }

  .col-3 {
    padding-right: 3px;
  }

  .custom-select {
    padding: 0.438rem 2rem 0.438rem 1rem;
    font-size: 12px;
  }
}

.avatar-section:disabled {
  background-color: #424141 !important;
  color: #b8c2cc !important;
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

.file-earmark-spreadsheet {
  color: white;
  margin-right: 10px;
}

.not-allowed {
  cursor: not-allowed !important;
}

.multi-dashboard-overlay {
  .rounded-sm {
    inset: -20px !important;
    height: auto !important;
  }
}

.donutChartContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.mainPieChartContainer {
  .card-body {
    padding: 1.85rem !important;
  }
}

.cardContainer {
  padding: 2px;
  height: 140px;

  .card-body {
    padding: 1.2rem;
  }
}

.cardItemContainer {
  /* padding: 10px; */
  justify-content: center;
  text-align: center;
  align-items: center;
}

.dateContainer {
  padding: 6px !important;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.picker-controller {
  background-color: #f5f6fa;
  padding: 8px;
  /* margin-top: 6px; */
}

.allChannelContainer {
  justify-content: center;
  align-items: center;
  height: 60px;
}

.projectOverviewTable {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(215, 215, 215);
  }

  th,
  td {
    color: black;
    padding: 8px;
    text-align: center;
    border: 0.5px solid #e7e7e7;
    font-size: 12px;
  }

  th {
    font-size: 13px;
    color: black;
    text-align: center;
    font-weight: bold;
    border: none;
    color: #868585;
    font-weight: bold;
    background-color: #ffffff !important;
  }
}

.client-selections {
  border-right: 10px solid transparent;
  outline: 1px solid #d8d6de;
  width: 100% !important;
  height: 35px;
  background-color: white;
  border: 0px;
  border-radius: 5px;
  padding-left: 8px;
  color: rgb(163, 153, 153);
}

@media only screen and (max-width: 700px) {
  .spendsTrackerContainer {
    width: 300%;
  }

  .mainPieChartContainer {
    width: 150%;
  }

  .piechartAndSpendsContainer {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
  }

  .donutChartContainer {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
    // justify-content: space-between;
    width: 80%;
    justify-content: center;
    align-items: center;

    .benchmarkPie {
      width: 300px !important;
    }
  }

  .filterContainer {
    display: grid;
    grid-template-columns: auto;
    // width: 70%;
    margin-left: auto;
    // margin-right: auto;
    height: auto;
  }

  .filterCardSelect {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .filterCard {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .centered-form {
    display: grid;
    grid-template-columns: auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  .allChannelContainer {
    display: grid;
    grid-template-columns: auto;
    width: 500px !important;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  .headerContainerItem {
    margin-top: 50px !important;
    width: 300px !important;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }

  .mainContainer {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    height: auto;
  }

  .cardContainer {
    margin-top: 50px;
    padding: 2px;
    height: 140px;
    width: 300px !important;
    margin-left: auto;
    margin-right: auto;

    .card-body {
      padding: 1.2rem;
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 1024px) {
  .addSpendsFormContainer {
    display: grid;
    grid-template-columns: auto;
    grid-gap: -20px !important;

    .w-100 {
      width: 710% !important;
    }

    .mb-1 {
      margin-bottom: 0px !important;
    }

    .formdiv {
      .form-control {
        padding: 0.438rem 1rem;
        background-color: #fff;
        background-clip: padding-box;
        width: 450px;
      }
    }
  }

  .dotLive {
    margin-left: 20px !important;
  }

  .allChannelContainer {
    .picker-controller {
      background-color: #f5f6fa;
      font-size: 11px;
      /* padding: 8px; */
    }
  }

  .filterCardIcons {
    .card-body {
      padding: 0.2rem !important;
    }

    .iconGroupContainer {
      margin-top: 25px !important;
    }

    .b-avatar .b-avatar-img img {
      width: 50%;
      height: 50%;
      max-height: auto;
      object-fit: cover;
    }
  }

  .piechartAndSpendsContainer {
    display: grid;
    grid-template-columns: auto;
  }

  .spendsTrackerContainer {
    width: 308%;
  }

  .mainPieChartContainer {
    width: 150%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .dotLive {
    margin-left: 20px !important;
    margin-top: -10px !important;
  }

  // .blink_text{
  //   margin-left: 10px !important;
  // }
  .mainPieChartCardContainer {
    height: 34.5vh;

    .card-body {
      padding: 0.2rem !important;
    }
  }

  .donutChartContainer {
    margin-top: 6vh;
  }

  .spendsCardTrackerContainer {
    margin-top: 2vh;

    span {
      font-size: 11px !important;
    }
  }

  .benchmarkPie {
    height: 60px !important;
  }

  // .piechartAndSpendsContainer{
  //   display: grid;
  //   grid-template-columns: auto;
  // }
  // .spendsTrackerContainer{
  //   width: 308%;
  // }
  // .mainPieChartContainer{
  //   width: 150%;
  // }
}
</style>
